import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/joy/CircularProgress';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getMetaDossierInfo } from 'cloudFunctions/functions.ts';
import { EditSyncElement } from 'components/Sync/SyncEntityEl.tsx';
import { getMetaDossierQueryKey } from 'queries/dossiers.ts';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import {
  TMetaDossierEntry,
  TMetaDossierResponse,
  TMetaDossierWipElByMetaPath,
  TSyncableTypes,
  TSyncValue,
  TWipMetaEl,
} from 'types/index';
import { isoDateStrToShortFrDateStr } from 'utils/dates.ts';

const dossierIdWithDataQuery = () => {
  const dossierId = useAppState.getState().getDossierId();

  const queryFunction = async (): Promise<TMetaDossierResponse | null> => {
    try {
      useInteractionsState.getState().setMetaDossierInfoIsLoading(true);
      const { data } = await getMetaDossierInfo({ dossierId });
      if (data) {
        useInteractionsState.getState().setMetaDossierInfo(data);
      }
      return data;
    } finally {
      useInteractionsState.getState().setMetaDossierInfoIsLoading(false);
    }
  };

  return queryOptions({
    queryKey: [getMetaDossierQueryKey(dossierId)],
    queryFn: queryFunction,
    staleTime: Infinity,
  });
};

type TSyncInfo = {
  metaPath: string;
  label: string;
  oldValue: TSyncValue;
  newValue: TSyncValue;
  type: TSyncableTypes;
  newValueHasError: boolean;
  wipInfo: TWipMetaEl;
};

type TComputeInfo = {
  chantier: TSyncInfo[];
  company: TSyncInfo[];
  benef: TSyncInfo[];
  ops: Record<string, { code: string; syncInfo: TSyncInfo[] }>;
  opsKeysSorted: string[];
};

const formatValue = (
  value: TSyncValue,
  type: TSyncableTypes,
  color?: 'success' | 'error'
) => {
  if (value === null) {
    return (
      <Stack direction={'row'} gap={0.5} alignItems={'center'}>
        <Typography
          level={'body-xs'}
          // @ts-ignore
          color={color ?? 'neutral'}
        >
          (Vide)
        </Typography>
      </Stack>
    );
  }
  switch (type) {
    case 'date-time':
      return (
        <Typography>{isoDateStrToShortFrDateStr(value as string)}</Typography>
      );
    case 'boolean':
      return value ? <CheckIcon></CheckIcon> : <CloseIcon></CloseIcon>;
    default:
      return <Typography>{value}</Typography>;
  }
};

export function SyncPanelEntry({
  label,
  oldValue,
  newValue,
  newValueHasError,
  type,
  wipInfo,
}: TSyncInfo) {
  const { t } = useTranslation();
  const color = newValueHasError ? 'error' : 'success';
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const setMetaFieldBeingEdited = useUiState(
    (state) => state.setMetaFieldBeingEdited
  );
  const metaFieldBeingEdited = useUiState(
    (state) => state.metaFieldBeingEdited
  );

  const isEditing = metaFieldBeingEdited === wipInfo.metaPath;

  return (
    <Stack
      className={'sync-panel-entry'}
      flexDirection={'column'}
      sx={{ mb: 1, cursor: isInReadOnlyMode ? 'default' : 'pointer' }}
      onClick={() =>
        wipInfo.metaPath === metaFieldBeingEdited
          ? setMetaFieldBeingEdited(null)
          : setMetaFieldBeingEdited(wipInfo.metaPath)
      }
    >
      <Typography level={'title-sm'}>
        <b>{label}</b>
      </Typography>

      <Stack direction={'row'} justifyContent={'space-between'} gap={1}>
        {oldValue === newValue ? (
          <div>{formatValue(oldValue, type)}</div>
        ) : (
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            sx={{ flexGrow: 1 }}
          >
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <AutoDeleteIcon></AutoDeleteIcon>
              <div>{formatValue(oldValue, type)}</div>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <FiberNewIcon
                // @ts-ignore
                color={color}
              ></FiberNewIcon>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={0.5}
                // @ts-ignore
                color={color}
              >
                {formatValue(newValue, type, color)}{' '}
                {newValueHasError ? t('sync.invalidExtra') : ''}
              </Stack>
            </Stack>
          </Stack>
        )}

        {!isEditing && (
          <IconButton
            onClick={() => setMetaFieldBeingEdited(wipInfo.metaPath)}
            disabled={isInReadOnlyMode}
            sx={{
              visibility: 'hidden',
              '.sync-panel-entry:hover &': {
                visibility: 'visible',
              },
            }}
          >
            <EditIcon> </EditIcon>
          </IconButton>
        )}
      </Stack>
      {isEditing && (
        <Box onClick={(e) => e.stopPropagation()} sx={{ mt: 1, mb: 1 }}>
          <EditSyncElement el={wipInfo}></EditSyncElement>
        </Box>
      )}
    </Stack>
  );
}

const computeDisplayInfo = (
  metaDossierRes: TMetaDossierResponse | null,
  metaDossierWipElByMetaPath: TMetaDossierWipElByMetaPath,
  editsOnly: boolean = true
): TComputeInfo => {
  const out: TComputeInfo = {
    chantier: [],
    benef: [],
    company: [],
    ops: {},
    opsKeysSorted: [],
  };

  if (!metaDossierRes) {
    return out;
  }

  for (const [metaPath, info] of metaDossierWipElByMetaPath.entries()) {
    const metaDossierEntry = get(
      metaDossierRes.metaDossierData,
      metaPath
    )! as TMetaDossierEntry;
    const infoCleaned: TSyncInfo = {
      metaPath,
      label: metaDossierEntry.label,
      oldValue: info.remoteValue,
      newValue: info.value,
      newValueHasError: info.hasError === true,
      type: metaDossierEntry.type,
      wipInfo: info,
    };
    if (
      metaPath.startsWith('chantier.') ||
      metaPath.startsWith('benef.') ||
      metaPath.startsWith('company.')
    ) {
      // metaPath will be like 'chantier.<field>' or 'benef.<field>' or 'company.<field>'
      const obj = metaPath.split('.')[0] as 'chantier' | 'benef' | 'company';
      if (!editsOnly || infoCleaned.oldValue !== infoCleaned.newValue) {
        out[obj].push(infoCleaned);
      }
    } else {
      // metaPath will be like 'operations.<rowIdEncoded>.data.<field>
      const rowIdEncoded = metaPath.split('.')[1];
      if (!out.ops[rowIdEncoded]) {
        out.ops[rowIdEncoded] = {
          code: metaDossierRes.metaDossierData.operations[rowIdEncoded]
            .codeAdeme,
          syncInfo: [],
        };
      }
      if (!editsOnly || infoCleaned.oldValue !== infoCleaned.newValue) {
        out.ops[rowIdEncoded].syncInfo.push(infoCleaned);
      }
    }
  }

  out.opsKeysSorted = [...Object.keys(out.ops)];
  out.opsKeysSorted.sort(
    (a, b) =>
      out.ops[a].code.localeCompare(out.ops[b].code) || a.localeCompare(b)
  );

  return out;
};

export function SyncPanel() {
  const { t } = useTranslation();

  const [editsOnly, setEditsOnly] = useState(true);

  const metaDossierInfoIsLoading = useInteractionsState(
    (state) => state.metaDossierInfoIsLoading
  );
  const metaDossierWipElByMetaPath = useInteractionsState(
    (state) => state.metaDossierWipElByMetaPath
  );
  const metaDossierChanges = useInteractionsState(
    (state) => state.metaDossierChanges
  );
  const isSaving = useInteractionsState(
    (state) => state.isSavingNewMetaDossierToAbrico
  );
  const saveNewMetaDossierToAbrico = useInteractionsState(
    (state) => state.saveNewMetaDossierToAbrico
  );

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  useEffect(() => {
    if (isInReadOnlyMode) {
      useUiState.getState().setMetaFieldBeingEdited(null);
    }
  }, [isInReadOnlyMode]);

  const { data: metaDossierRes } = useSuspenseQuery(dossierIdWithDataQuery());

  const infos = useMemo<TComputeInfo>(
    () =>
      computeDisplayInfo(metaDossierRes, metaDossierWipElByMetaPath, editsOnly),
    [metaDossierRes, metaDossierWipElByMetaPath, editsOnly]
  );

  if (!metaDossierRes) {
    return <Typography>{t('sync.noMetaDossier')}</Typography>;
  }

  return (
    <>
      {metaDossierInfoIsLoading ? (
        <Stack gap={1} direction={'row'} alignItems={'center'}>
          <Typography level={'body-xs'}>
            {t('sync.dataUpdateInProgress')}
          </Typography>
          <CircularProgress size={'sm'} />
        </Stack>
      ) : (
        <Typography level={'body-xs'}>{t('sync.dataIsUpToData')}</Typography>
      )}
      <Stack gap={1}>
        <Typography
          component="label"
          sx={{ mb: 1 }}
          endDecorator={
            <Switch
              sx={{ ml: 1 }}
              checked={editsOnly}
              onChange={(event) => setEditsOnly(event.target.checked)}
            />
          }
        >
          {t('sync.editsOnlySwitchLabel')}
        </Typography>
        {(['benef', 'company', 'chantier'] as const).map((obj) => (
          <Box key={obj} sx={{ mb: 1 }}>
            <Typography level={'title-lg'} sx={{ mb: 0.5 }}>
              {t(`sync.${obj}SectionTitle`)}
            </Typography>
            <Card>
              {infos[obj].length === 0 ? (
                <Typography>
                  {editsOnly ? t('sync.noEdits') : t('sync.noInfo')}
                </Typography>
              ) : (
                infos[obj].map((el) => (
                  <Box key={el.metaPath}>
                    <SyncPanelEntry {...el} />
                  </Box>
                ))
              )}
            </Card>
          </Box>
        ))}
        <Typography level={'title-lg'} sx={{ mb: 0.5, mt: 1 }}>
          {t('sync.opsSectionTitle')}
        </Typography>
        {infos.opsKeysSorted.length === 0 ? (
          <Typography>{t('sync.noOps')}</Typography>
        ) : (
          infos.opsKeysSorted.map((opKey) => (
            <Card key={opKey}>
              <Typography level={'title-md'} sx={{ mb: 0.5 }}>
                🛠️ {infos.ops[opKey].code}
              </Typography>
              {infos.ops[opKey]!.syncInfo.length === 0 ? (
                <Typography>
                  {editsOnly ? t('sync.noEdits') : t('sync.noInfo')}
                </Typography>
              ) : (
                infos.ops[opKey]!.syncInfo.map((el) => (
                  <Box key={el.metaPath}>
                    <SyncPanelEntry {...el} />
                  </Box>
                ))
              )}
            </Card>
          ))
        )}
      </Stack>

      <Stack>
        {isSaving ? (
          <CircularProgress />
        ) : (
          <Button
            disabled={Object.keys(metaDossierChanges).length === 0}
            sx={{ m: 2 }}
            onClick={saveNewMetaDossierToAbrico}
          >
            {t('sync.syncBtn')}
          </Button>
        )}
      </Stack>
    </>
  );
}
