import { enqueueSnackbar } from 'notistack';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { generateReviewFeedback } from 'cloudFunctions/functions.ts';
import i18n from 'i18n.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { TReviewInfo } from 'types/index';

interface UiState {
  focusedEntityId: string;
  autoFocus: boolean;
  setFocusedEntityId: (id: string, autoFocus?: boolean) => void;
  viewerIsReady: boolean;
  setViewerIsReady: (isReady: boolean) => void;
  documentFullyLoaded: boolean;
  setDocumentFullyLoaded: (isLoaded: boolean) => void;
  metaFieldBeingEdited: string | null;
  setMetaFieldBeingEdited: (field: string | null) => void;
  reviewFeedbackMarkdown: string;
  setReviewMarkdown: (markdown: string) => void;
  generateAiReviewFeedback: (reviewInfo: TReviewInfo) => Promise<void>;
  generateAiReviewFeedbackLoading: boolean;
  forcedShownSections: Set<string>;
  toggleForcedShownSection: (section: string) => void;
}

export const useUiState = create<UiState>()(
  devtools(
    (set) => ({
      viewerIsReady: false as boolean,
      setViewerIsReady: (isReady: boolean) => {
        set(() => ({ viewerIsReady: isReady }));
      },
      documentFullyLoaded: false as boolean,
      setDocumentFullyLoaded: (isLoaded: boolean) => {
        set(() => ({ documentFullyLoaded: isLoaded }));
      },
      autoFocus: false as boolean,
      focusedEntityId: '',
      setFocusedEntityId: (id: string, autoFocus: boolean = true) => {
        set(() => ({ focusedEntityId: id, autoFocus }));
      },
      metaFieldBeingEdited: '' as string | null,
      setMetaFieldBeingEdited: (field: string | null) => {
        set(() => ({ metaFieldBeingEdited: field }));
      },
      reviewFeedbackMarkdown: '' as string,
      setReviewMarkdown: (markdown: string) => {
        set(() => ({ reviewFeedbackMarkdown: markdown }));
      },
      generateAiReviewFeedback: async (reviewInfo: TReviewInfo) => {
        set({ generateAiReviewFeedbackLoading: true });
        try {
          const { data } = await generateReviewFeedback({
            dossierId: (await useInteractionsState.getState().getDossier()).id,
            rejections: reviewInfo.rejectedEntities.map((el) => ({
              label: el.label,
              rejectionReason: el.rejectionReason,
              isMissing: el.kind === 'missing',
            })),
          });
          if (!data.succeeded) {
            throw new Error('Failed to generate feedback');
          }

          const { reviewFeedbackMarkdown } = data;
          set({ reviewFeedbackMarkdown });
          enqueueSnackbar(i18n.t('review.ai.notification.success'), {
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          enqueueSnackbar(i18n.t('review.ai.notification.error'), {
            variant: 'error',
          });
        } finally {
          set({ generateAiReviewFeedbackLoading: false });
        }
      },
      generateAiReviewFeedbackLoading: false as boolean,
      forcedShownSections: new Set<string>(),
      toggleForcedShownSection: (section: string) => {
        set((state) => {
          const forcedShownSections = new Set(state.forcedShownSections);
          if (forcedShownSections.has(section)) {
            forcedShownSections.delete(section);
          } else {
            forcedShownSections.add(section);
          }
          return { forcedShownSections };
        });
      },
    }),
    {
      name: 'ui-store',
    }
  )
);
