import Add from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import DialogTitle from '@mui/joy/DialogTitle';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import SelectList from 'components/Tag/SelectTag';
import SelectCodeAdeme from 'components/UI/SelectCodeAdeme';
import { switchButton } from 'components/UI/switchButton';
import { LIST_DOSSIERS_QUERY_KEY } from 'hooks/useDossiers';
import { createNewDossier } from 'queries/dossiers';
import { useAppState } from 'stores/appStore.ts';
import { getPDFNet } from 'utils/apryze.ts';
import { logDurationInFs } from 'utils/timings.ts';

const FileModalDialog = () => {
  const { t } = useTranslation('common');
  const { company, user } = useAppState(
    useShallow((state) => ({ company: state.company, user: state.user }))
  );
  const [dossierName, setDossierName] = useState<string>('');
  const [isUniqueDevis, setIsUniqueDevis] = useState<boolean>(false);
  const navigate = useNavigate();
  const { tagId: currentTagId } = useParams();
  const [selectedTagId, setSelectedTagId] = useState<string>(
    currentTagId ?? ''
  );

  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const [codesAdemeSelected, setCodesAdemeSelected] = useState<
    { code: string; name: string }[]
  >([]);

  useEffect(() => {
    // We initialize the PDFNet library early for later use
    getPDFNet();
  }, []);

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: {
      files: FileList;
      name: string;
      userEmail: string;
      companyId: string;
      isUniqueDevis: boolean;
      tagId: string;
      code: { code: string; name: string }[];
    }) => {
      const start = performance.now();
      await createNewDossier(
        data.name,
        data.files,
        data.userEmail,
        data.companyId,
        data.isUniqueDevis,
        data.tagId,
        data.code
      );
      const end = performance.now();
      logDurationInFs({ durationMs: end - start, name: 'Create Dossier' });
    },
    onSuccess: async () => {
      // Invalidate and refetch
      await queryClient.invalidateQueries({
        queryKey: [LIST_DOSSIERS_QUERY_KEY],
      });
      handleClose();
      navigate(`/company/${company?.id}/dossiers/tag/${selectedTagId}`);
    },
    onError: (error) => {
      if (error.message === 'duplicate') {
        alert(t('dossier.actions.error.duplicate'));
      } else {
        alert(t('dossier.actions.error.create'));
        console.error('Error creating new dossier:', error);
      }
    },
  });

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isPending) {
        e.preventDefault();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    // we clean our event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isPending]);

  useEffect(() => {
    setSelectedTagId(currentTagId ?? '');
  }, [currentTagId]);

  const handleClose = () => {
    setOpen(false);
    setDossierName('');
    setSelectedFiles(null);
    setHelperText([]);
    setIsUniqueDevis(false);
    setSelectedTagId(currentTagId ?? '');
    setCodesAdemeSelected([]);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) {
      alert(t('file.upload.error.noFile'));
      return;
    }

    setSelectedFiles(e.target.files);

    const filesNames = Array.from(e.target.files).map((file) => file.name);

    setHelperText(filesNames);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isPending) return;

    if (
      selectedFiles &&
      company?.id &&
      user?.email &&
      codesAdemeSelected.length !== 0
    ) {
      const data = {
        files: selectedFiles,
        name: dossierName,
        userEmail: user.email,
        companyId: company.id,
        isUniqueDevis: isUniqueDevis,
        tagId: selectedTagId,
        code: codesAdemeSelected,
      };
      mutate(data);
    }
    if (!selectedFiles) {
      alert(t('file.upload.error.noFile'));
    }
    if (codesAdemeSelected.length === 0) {
      alert(t('codeAdeme.error'));
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        {t('dossier.actions.add')}
      </Button>
      <Modal
        open={open}
        onClose={() => {
          if (!isPending) setOpen(false);
        }}
      >
        <ModalDialog size="lg" sx={{ minWidth: { md: 400 } }}>
          <DialogTitle>
            {isPending
              ? t('dossier.actions.creating')
              : t('dossier.actions.create')}
          </DialogTitle>
          <Stack
            style={{ display: isPending ? undefined : 'none' }}
            alignItems={'center'}
            alignContent={'center'}
          >
            <CircularProgress />
          </Stack>

          <form
            onSubmit={handleSubmit}
            style={{ display: isPending ? 'none' : undefined }}
          >
            <Stack spacing={3}>
              <FormControl required>
                <FormLabel> {t('dossier.actions.createName')}</FormLabel>
                <Input
                  value={dossierName}
                  autoFocus
                  required
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setDossierName(e.target.value)
                  }
                />
              </FormControl>

              <FormControl required>
                <FormLabel> {t('tags.choose')}</FormLabel>
                <SelectList
                  selectedTagId={selectedTagId || ''}
                  setSelectedTagId={setSelectedTagId}
                />
              </FormControl>

              <FormControl required>
                <FormLabel> {t('codeAdeme.choose')}</FormLabel>
                <SelectCodeAdeme
                  selectedCodes={codesAdemeSelected}
                  setSelectedCodes={setCodesAdemeSelected}
                />
              </FormControl>

              <Stack sx={{ width: '100%' }} spacing={1}>
                <Button
                  component="label" // important to use this prop for the input below to be clickable
                  tabIndex={-1}
                  startDecorator={<CloudUploadOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                  sx={{ width: 'inherit' }}
                >
                  <input
                    type="file"
                    multiple
                    accept="application/pdf,image/jpeg,image/png"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                  {t('file.upload.title')}
                </Button>
                {helperText.length !== 0 &&
                  helperText.map((text, index) => (
                    <Typography
                      key={`${text}-${index}`}
                      color="primary"
                      level="body-xs"
                    >
                      {text}
                    </Typography>
                  ))}
              </Stack>

              {selectedFiles?.length == 1 && (
                <FormControl>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      border: '1px dashed',
                      borderRadius: '4px',
                      padding: '8px',
                    }}
                  >
                    <FormLabel>{t('dossier.isUniqueDevis')}</FormLabel>
                    {switchButton({
                      checked: isUniqueDevis,
                      setChecked: setIsUniqueDevis,
                    })}
                  </Stack>
                </FormControl>
              )}
            </Stack>
            <Box
              sx={{
                mt: 5,
                display: 'flex',
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row-reverse' },
              }}
            >
              <Button type="submit">{t('common.actions.validate')}</Button>
              <Button onClick={handleClose} variant="outlined">
                {t('common.actions.cancel')}
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default FileModalDialog;
