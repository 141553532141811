import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
} from '@mui/joy';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { forceReleaseRWLockOwnershipAndReload } from 'queries/dossiersRWLocks.ts';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { formatTimeStamp } from 'utils/formatDates.ts';

const ReadOnlyModal = () => {
  const {
    roModalOpen,
    lockInfo,
    setRoModalOpen,
    openedInRWMode,
    isTemporaryReadOnlyMode,
  } = useInteractionsState(
    useShallow((state) => ({
      roModalOpen: state.roModalOpen,
      lockInfo: state.lockInfo,
      setRoModalOpen: state.setRoModalOpen,
      openedInRWMode: state.openedInRWMode,
      isTemporaryReadOnlyMode: state.isTemporaryReadOnlyMode,
    }))
  );
  const user = useAppState((state) => state.user);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Modal open={roModalOpen} onClose={() => setRoModalOpen(false)}>
        <ModalDialog>
          <DialogTitle
            dangerouslySetInnerHTML={{
              __html: t('rwLock.dialog.title'),
            }}
          ></DialogTitle>
          {isTemporaryReadOnlyMode ? (
            <>
              <DialogContent>
                <Typography>
                  {t('rwLock.dialog.explanationTemporaryRo')}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setRoModalOpen(false)}>
                  {t('rwLock.dialog.okBtn')}
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogContent>
                {openedInRWMode ? (
                  <>
                    <Typography>
                      {user?.uid === lockInfo?.owner?.uid
                        ? t('rwLock.dialog.explanationRwOpenButOtherTab')
                        : t('rwLock.dialog.explanationRwOpenButOtherUser')}{' '}
                      (
                      {!lockInfo?.owner?.uid ||
                      user?.uid === lockInfo?.owner?.uid
                        ? ''
                        : `${lockInfo?.owner?.email} - `}
                      {formatTimeStamp(lockInfo?.createdAt)}
                      ).{' '}
                    </Typography>

                    <Typography color={'danger'}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'rwLock.dialog.explanationRwOpenButOtherExtra'
                          ),
                        }}
                      ></span>
                    </Typography>
                  </>
                ) : (
                  <Typography>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          user?.uid === lockInfo?.owner?.uid
                            ? t('rwLock.dialog.explanationRoOpenButOtherTab')
                            : t('rwLock.dialog.explanationRoOpenButOtherUser'),
                      }}
                    ></span>{' '}
                    (
                    {lockInfo?.owner?.uid && user?.uid === lockInfo?.owner?.uid
                      ? ''
                      : `${lockInfo?.owner?.email} - `}
                    {formatTimeStamp(lockInfo?.createdAt)}
                    ).{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'rwLock.dialog.explanationRoOpenButOtherExtra'
                        ),
                      }}
                    ></span>
                  </Typography>
                )}

                {lockInfo?.owner && (
                  <Typography sx={{ mt: 1 }} color={'danger'}>
                    {lockInfo?.owner?.uid === user?.uid
                      ? t('rwLock.dialog.disclaimerSwitchOtherTab')
                      : t('rwLock.dialog.disclaimerSwitchOtherUser')}
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setRoModalOpen(false)}>
                  {t('rwLock.dialog.stickToRoBtn')}
                </Button>

                <Button
                  // Releasing the lock with a refresh will cause the tab to take the lock again on next load
                  variant={'outlined'}
                  onClick={async () =>
                    await forceReleaseRWLockOwnershipAndReload(
                      useAppState.getState().getDossierId()
                    )
                  }
                >
                  {t('rwLock.dialog.switchToRwBtn')}
                </Button>
              </DialogActions>
            </>
          )}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default ReadOnlyModal;
