import '@fontsource/inter';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { updateProfile } from 'firebase/auth';
import * as React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import FullScreenLoader from 'components/UI/FullScreenLoader.tsx';
import Home from 'components/UI/Home';
import { auth } from 'config/firebaseInit';
import { useAppState } from 'stores/appStore.ts';
import { isClientSide, isProductionEnv } from 'utils/helpers';
import { capitalizeFirstLetterOfEachWord } from 'utils/string.ts';

const hasAddBlocker = document.getElementById('eLAtIRdxngZP') === null;

function ProtectedRoot() {
  const { t } = useTranslation();
  const [user, loading] = useAuthState(auth);
  const { setUser, company, setUrlParams } = useAppState(
    useShallow((state) => ({
      setUser: state.setUser,
      company: state.company,
      setUrlParams: state.setUrlParams,
    }))
  );
  const params = useParams();

  React.useEffect(() => {
    if (user) {
      if (!user.displayName) {
        updateProfile(user, {
          displayName: capitalizeFirstLetterOfEachWord(
            user.email!.split('@')[0].split('+')[0].split('.').join(' ')
          ),
        }).then(() => {
          console.log('Profile display name auto-updated');
        });
      }

      setUser(user);
    }
  }, [user, setUser]);

  React.useEffect(() => {
    setUrlParams(params as Record<string, string>);
  }, [params, setUrlParams]);

  React.useEffect(() => {
    if (isClientSide()) {
      hotjar.initialize(
        parseInt(
          isProductionEnv() ? import.meta.env.VITE_HOTJAR_ID || '0' : '0'
        ),
        6
      );
    }
  }, []);

  if (hasAddBlocker) {
    return (
      <Stack
        minHeight="100vh"
        minWidth="100vw"
        alignItems={'center'}
        justifyContent={'center'}
        direction={'row'}
      >
        <Stack
          maxWidth={'600px'}
          padding={2}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          justifyItems={'center'}
          gap={2}
        >
          <Typography level={'h2'} textAlign={'center'}>
            {t('adBlock.title')}
          </Typography>
          <Typography textAlign={'center'}>
            <span
              dangerouslySetInnerHTML={{ __html: t('adBlock.description') }}
            ></span>
          </Typography>
        </Stack>
      </Stack>
    );
  }

  if (loading || (user && !company)) return <FullScreenLoader />;
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
      {user && company ? (
        <Home companyId={company?.id} />
      ) : (
        <Navigate to="/login" />
      )}
    </Box>
  );
}

export default ProtectedRoot;
