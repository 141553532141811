import MoveDownIcon from '@mui/icons-material/MoveDown';
import ReplayIcon from '@mui/icons-material/Replay';
import { Textarea } from '@mui/joy';
import Autocomplete from '@mui/joy/Autocomplete';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { TSyncValue, TWipMetaEl } from 'types/index';
import {
  capitalizeFirstLetterOfEachWord,
  findMostCommonWords,
} from 'utils/string.ts';

function SyncEntityElNumber({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value: number = parseFloat(e.target.value);
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaPath, isNaN(value) ? null : value);
    },
    [el.metaPath]
  );

  return (
    <FormControl size={'sm'}>
      <FormLabel sx={{ p: 0, m: 0 }}>{el.label}</FormLabel>

      <Input
        type={'number'}
        value={(el.value as number) || ''}
        variant={'solid'}
        disabled={isInReadOnlyMode}
        onChange={onChange}
        error={el.hasError === true}
        sx={{ width: 200 }}
      ></Input>
    </FormControl>
  );
}

function SyncEntityElStringEmail({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaPath, value || null);
    },
    [el.metaPath]
  );

  return (
    <FormControl size={'sm'}>
      <FormLabel sx={{ p: 0, m: 0 }}>{el.label}</FormLabel>
      <Input
        value={(el.value as string) || ''}
        variant={'solid'}
        disabled={isInReadOnlyMode}
        onChange={onChange}
        error={el.hasError === true}
        sx={{ width: 200 }}
      ></Input>
    </FormControl>
  );
}

function SyncEntityElString({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChangeTextArea = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaPath, e.target.value || null);
    },
    [el.metaPath]
  );

  const handleChangeSelect = useCallback(
    // @ts-ignore
    (_, newValue: string | null) => {
      useInteractionsState
        .getState()
        .setMetaDossierWipEl(el.metaPath, newValue || null);
    },
    [el.metaPath]
  );

  if (Array.isArray(el.stringOptions)) {
    return (
      <FormControl size={'sm'}>
        <FormLabel sx={{ p: 0, m: 0 }}>{el.label}</FormLabel>

        <Autocomplete
          options={el.stringOptions}
          onChange={handleChangeSelect}
          sx={{ margin: 1 / 2, width: '200px!important' }}
          size={'md'}
          value={(el.value as string) || ''}
          disabled={isInReadOnlyMode}
          variant={'solid'}
          color={el.hasError === true ? 'danger' : 'neutral'}
        ></Autocomplete>
      </FormControl>
    );
  }

  return (
    <FormControl size={'sm'}>
      <FormLabel sx={{ p: 0, m: 0 }}>{el.label}</FormLabel>
      <Textarea
        variant={'solid'}
        sx={{ margin: 1 / 2, width: 200 }}
        size={'sm'}
        value={(el.value as string) || ''}
        disabled={isInReadOnlyMode}
        onChange={onChangeTextArea}
        error={el.hasError === true}
      ></Textarea>
    </FormControl>
  );
}

function SyncEntityElDateTime({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // We try to reuse the time part of the remote value (if present)
      // At the moment we only manage the date part, as we don't have the need to handle time too
      const extraTsPart = ((el.remoteValue as string) || '').includes('T')
        ? ((el.remoteValue as string) || '').split('T')[1]
        : '00:00:00.000Z';

      useInteractionsState
        .getState()
        .setMetaDossierWipEl(
          el.metaPath,
          e.target.value ? `${e.target.value}T${extraTsPart}` : null
        );
    },
    [el.metaPath, el.remoteValue]
  );

  return (
    <FormControl size={'sm'}>
      <FormLabel sx={{ p: 0, m: 0 }}>{el.label}</FormLabel>
      <Input
        type={'date'}
        value={((el.value as string) || '').split('T')[0]}
        variant={'solid'}
        disabled={isInReadOnlyMode}
        onChange={onChange}
        error={el.hasError === true}
      ></Input>
    </FormControl>
  );
}

function SyncEntityElBoolean({ el }: { el: TWipMetaEl }) {
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const valueByString = {
        true: true,
        false: false,
        null: null,
      } as const;

      useInteractionsState
        .getState()
        .setMetaDossierWipEl(
          el.metaPath,
          valueByString[e.target.value as 'true' | 'false' | 'null']
        );
    },
    [el.metaPath]
  );

  return (
    <Card variant={isInReadOnlyMode ? 'soft' : 'solid'} color={'neutral'}>
      <FormControl size={'sm'}>
        <FormLabel sx={{ p: 0, m: 0 }}>{el.label}</FormLabel>
        <RadioGroup
          value={el.value}
          onChange={onChange}
          orientation={'horizontal'}
          size={'sm'}
        >
          <Radio
            value="true"
            label="Oui"
            variant="outlined"
            disabled={isInReadOnlyMode}
          />
          <Radio
            value="false"
            label="Non"
            variant="outlined"
            disabled={isInReadOnlyMode}
          />
          <Radio
            value="null"
            label="?"
            variant="outlined"
            disabled={isInReadOnlyMode}
          />
        </RadioGroup>
      </FormControl>
    </Card>
  );
}

export function EditSyncElement({ el }: { el: TWipMetaEl }) {
  switch (el.type) {
    case 'string':
      return <SyncEntityElString el={el} />;
    case 'number':
      return <SyncEntityElNumber el={el} />;
    case 'date-time':
      return <SyncEntityElDateTime el={el} />;
    case 'boolean':
      return <SyncEntityElBoolean el={el} />;
    case 'email-address':
      return <SyncEntityElStringEmail el={el} />;
    default:
      return <Typography>Unsupported type</Typography>;
  }
}

function cleanDocText(el: TWipMetaEl, docText?: string): TSyncValue {
  if (!docText) {
    return null;
  }

  if (el.type === 'string') {
    if (Array.isArray(el.stringOptions)) {
      // We try to look for the best option
      return findMostCommonWords(docText, el.stringOptions);
    } else {
      return docText.trim();
    }
  } else if (el.type === 'number') {
    // We split on alphanumerical chracters to skip units
    const cleanedValue = docText.split(/[A-Za-z]+/)[0].replace(/[^0-9.,]/g, '');
    // we cast to int by default
    const parsedValue = parseInt(cleanedValue);
    if (isNaN(parsedValue)) {
      return null;
    }
    return parsedValue;
  } else if (el.type === 'date-time') {
    // we don't support parsing date-time from docText
    return null;
  } else if (el.type === 'boolean') {
    // we don't support parsing boolean from docText
    return null;
  } else if (el.type === 'email-address') {
    return docText.trim();
  } else {
    throw new Error('Unsupported type: ${el.type}');
  }
}

function SyncEntityElEntry({
  docText,
  entry,
}: {
  docText?: string;
  entry: TWipMetaEl;
}) {
  const possiblyTransferredValue = useMemo(() => {
    return cleanDocText(entry, docText);
  }, [docText, entry]);

  const handleTransferValue = useCallback(
    (e: MouseEvent) => {
      let valueToTransfer: TSyncValue = possiblyTransferredValue;
      if (typeof valueToTransfer === 'string') {
        if (e.shiftKey) {
          valueToTransfer = valueToTransfer.toLowerCase();
        } else if (e.ctrlKey) {
          valueToTransfer = capitalizeFirstLetterOfEachWord(valueToTransfer);
        }
      }

      useInteractionsState
        .getState()
        .setMetaDossierWipEl(entry.metaPath, valueToTransfer);
    },
    [entry.metaPath, possiblyTransferredValue]
  );

  const handleResetValue = useCallback(() => {
    useInteractionsState
      .getState()
      .setMetaDossierWipEl(entry.metaPath, entry.remoteValue);
  }, [entry.metaPath, entry.remoteValue]);

  return (
    <>
      <Stack key={entry.metaPath} flexDirection={'row'} alignItems={'flex-end'}>
        <Avatar
          src="/abrico_letter.svg"
          sx={{
            '--Avatar-size': '1.5rem',
            mr: 0.5,
            mb: 1,
          }}
        />
        <EditSyncElement el={entry} />
        <IconButton
          size={'sm'}
          disabled={!possiblyTransferredValue}
          sx={{ ml: '4px', mb: 1 }}
          onClick={handleTransferValue}
        >
          <MoveDownIcon sx={{ transform: 'scale(-1, 1)' }} />
        </IconButton>
        <IconButton
          size={'sm'}
          sx={{ mb: 1 }}
          disabled={entry.value === entry.remoteValue}
          onClick={handleResetValue}
        >
          <ReplayIcon />
        </IconButton>
        {/* We hack around some spacing */}
        <Box width={'58px'}></Box>
      </Stack>
    </>
  );
}

export function SyncEntityEl({
  entityId,
  docText,
}: {
  entityId: string;
  docText?: string;
}) {
  const links = useInteractionsState((state) =>
    state.metaDossierInfo &&
    Array.isArray(state.metaDossierInfo.metaDossierLinkByAnnotId[entityId])
      ? state.metaDossierInfo.metaDossierLinkByAnnotId[entityId]
      : []
  );

  const elements = useInteractionsState(
    useShallow((state) =>
      links.map(
        (link) =>
          state.metaDossierWipElByMetaPath.get(link.metaDossierDataPath)!
      )
    )
  );

  if (!links.length) {
    return <></>;
  }

  return (
    <>
      <Divider sx={{ m: 0.5 }} />
      <Stack
        // Prevent event from bubbling to EntityCard which would cause an auto-select to happen
        onKeyDown={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
          useUiState.getState().setFocusedEntityId(entityId, false);
        }}
        onMouseMove={(e) => e.stopPropagation()}
        direction={'column'}
        width={'100%'}
        alignItems={'end'}
        gap={0.5}
      >
        {elements.map((el) => (
          <SyncEntityElEntry key={el.metaPath} docText={docText} entry={el} />
        ))}
      </Stack>
    </>
  );
}
