import { WebViewerInstance } from '@pdftron/webviewer';
import { enqueueSnackbar } from 'notistack';
import { z } from 'zod';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { updateAbricoMetaDossier } from 'cloudFunctions/functions.ts';
import i18n from 'i18n.ts';
import { queryClient } from 'queries';
import { getMetaDossierQueryKey } from 'queries/dossiers.ts';
import { useAppState } from 'stores/appStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import {
  AnnotStatus,
  IDossierLock,
  TEntityVersionInfo,
  TInteractionsFS,
  TMetaDossierEntry,
  TMetaDossierResponse,
  TMetaDossierWipElByMetaPath,
  TSyncValue,
} from 'types/index';
import { TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS } from 'utils/constants.ts';
import { Dossier } from 'utils/dossier.ts';

const disableEditElementsAndFeatures = (instance: WebViewerInstance) => {
  const { disableFeatures, disableElements, Feature } = instance.UI;

  disableElements(TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS);
  disableFeatures([Feature.ThumbnailReordering, Feature.ThumbnailMerging]);
  instance.Core.annotationManager.enableReadOnlyMode();
};

const enableEditElementsAndFeatures = (instance: WebViewerInstance) => {
  const { enableFeatures, enableElements, Feature } = instance.UI;

  enableElements(TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS);
  enableFeatures([Feature.ThumbnailReordering, Feature.ThumbnailMerging]);
  instance.Core.annotationManager.disableReadOnlyMode();
};

interface InteractionsState {
  getDataForFs: () => TInteractionsFS;
  mergeData: (data: TInteractionsFS) => void;
  instance: WebViewerInstance;
  setInstance: (instance: WebViewerInstance) => void;
  dossier: Dossier | null;
  setDossier: (dossier: Dossier) => void;
  getDossier: () => Promise<Dossier>;
  openedInRWMode: boolean | null;
  setOpenedInRWMode: (opened: boolean) => void;
  roModalOpen: boolean;
  setRoModalOpen: (open: boolean) => void;
  lockInfo: IDossierLock | null;
  setLockInfo: (lockInfo: IDossierLock) => void;
  isInReadOnlyMode: boolean;
  isTemporaryReadOnlyMode: boolean;
  enableReadOnlyMode: (temporary?: boolean) => void;
  disableReadOnlyMode: () => void;
  hasChangesThatRequireSave: boolean;
  setHasChangesThatRequireSave: (hasChanges: boolean) => void;
  entitiesStatuses: Map<string, AnnotStatus>;
  setEntityStatus: (
    id: string,
    targetStatus: AnnotStatus,
    force?: boolean
  ) => void;
  prevVersionData: Map<string, TEntityVersionInfo>;
  getEntityStatus: (id: string) => AnnotStatus;
  entitiesRejectionReasons: Map<string, string>;
  setEntityRejectionReason: (entityId: string, reason: string) => void;
  getEntityRejectionReason: (entityId: string) => string;
  metaDossierInfoIsLoading: boolean;
  setMetaDossierInfoIsLoading: (isLoading: boolean) => void;
  metaDossierInfo: TMetaDossierResponse | undefined;
  setMetaDossierInfo: (metaDossierInfo: TMetaDossierResponse) => void;
  metaDossierWipElByMetaPath: TMetaDossierWipElByMetaPath;
  setMetaDossierWipEl: (metaPath: string, value: TSyncValue) => void;
  metaDossierChanges: Record<string, TSyncValue>;
  setMetaDossierChanges: (
    metaDossierChanges: Record<string, TSyncValue>
  ) => void;
  saveNewMetaDossierToAbrico: () => Promise<void>;
  isSavingNewMetaDossierToAbrico: boolean;
}

export const useInteractionsState = create<InteractionsState>()(
  devtools(
    (set, get, store) => ({
      getDataForFs: (): TInteractionsFS => {
        return {
          entitiesStatuses: Object.fromEntries(
            get().entitiesStatuses.entries()
          ),
          entitiesRejectionReasons: Object.fromEntries(
            get().entitiesRejectionReasons.entries()
          ),
        };
      },
      mergeData(data: TInteractionsFS) {
        set(() => ({
          entitiesStatuses: new Map([
            ...get().entitiesStatuses.entries(),
            ...Object.entries(data.entitiesStatuses || {}),
          ]),
          entitiesRejectionReasons: new Map([
            ...get().entitiesRejectionReasons.entries(),
            ...Object.entries(data.entitiesRejectionReasons || {}),
          ]),
        }));

        if (data.prevVersion?.data) {
          set(() => ({
            prevVersionData: new Map(Object.entries(data.prevVersion!.data)),
          }));
        }
      },
      // @ts-ignore
      instance: null as WebViewerInstance,
      setInstance: (instance: WebViewerInstance) => {
        set(() => ({ instance }));
        // We also resync the UI
        const state = get();
        if (state.isInReadOnlyMode) {
          state.enableReadOnlyMode();
        } else {
          state.disableReadOnlyMode();
        }
      },
      dossier: null as Dossier | null,
      setDossier: (dossier: Dossier) => {
        set(() => ({ dossier }));
      },
      getDossier: async () => {
        if (get().dossier) {
          return get().dossier!;
        }
        return new Promise((resolve) => {
          const unsubscribe = store.subscribe((state) => {
            if (state.dossier) {
              unsubscribe();
              resolve(state.dossier! as Dossier);
            }
          });
        });
      },
      openedInRWMode: null as boolean | null,
      setOpenedInRWMode: (opened: boolean) => {
        set(() => ({ openedInRWMode: opened }));
      },
      roModalOpen: false as boolean,
      setRoModalOpen: (open: boolean) => {
        set(() => ({ roModalOpen: open }));
      },
      isInReadOnlyMode: false as boolean,
      isTemporaryReadOnlyMode: false as boolean,
      lockInfo: null as IDossierLock | null,
      setLockInfo: (lockInfo: IDossierLock) => {
        set(() => ({ lockInfo: lockInfo }));
      },
      enableReadOnlyMode: (temporary: boolean = false) => {
        set(() => ({
          isInReadOnlyMode: true,
          isTemporaryReadOnlyMode: temporary,
        }));
        const state = get();
        if (state.instance) {
          disableEditElementsAndFeatures(get().instance);
        }
      },
      disableReadOnlyMode: () => {
        set(() => ({ isInReadOnlyMode: false }));
        const state = get();
        if (state.instance) {
          enableEditElementsAndFeatures(get().instance);
        }
      },
      hasChangesThatRequireSave: false as boolean,
      setHasChangesThatRequireSave: (hasChanges: boolean) => {
        set(() => ({ hasChangesThatRequireSave: hasChanges }));
      },
      prevVersionData: new Map(),
      entitiesStatuses: new Map(),
      getEntityStatus: (id: string) => {
        return get().entitiesStatuses.get(id) || AnnotStatus.PENDING;
      },
      // We allow to force the status to be set, to bypass intermediate PENDING state
      // When using keyboard we cannot jump from VALIDATED to REJECTED or vice versa
      // But when we click on the UI we want to force the generation of the new status
      setEntityStatus: (
        id: string,
        targetStatus: AnnotStatus,
        force: boolean = false
      ) => {
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }
        if (!useUiState.getState().documentFullyLoaded) {
          enqueueSnackbar(i18n.t('validation.docNotFullyLoaded'), {
            variant: 'info',
          });
          return;
        }
        const prev = get().entitiesStatuses;
        // All statuses might not be set, by default they will be in PENDING
        const currentStatus = prev.get(id) || AnnotStatus.PENDING;
        if (currentStatus === targetStatus) {
          return;
        }

        const next = new Map(prev);

        if (
          force ||
          targetStatus === AnnotStatus.PENDING ||
          currentStatus === AnnotStatus.PENDING
        ) {
          next.set(id, targetStatus);
        } else {
          if (targetStatus === AnnotStatus.VALIDATED) {
            switch (currentStatus) {
              case AnnotStatus.VALIDATED:
                break;
              case AnnotStatus.REJECTED:
                next.set(id, AnnotStatus.PENDING);
                break;
              default:
                break;
            }
          } else if (targetStatus === AnnotStatus.REJECTED) {
            switch (currentStatus) {
              case AnnotStatus.VALIDATED:
                next.set(id, AnnotStatus.PENDING);
                break;
              case AnnotStatus.REJECTED:
                break;
              default:
                break;
            }
          } else {
            throw new Error('Invalid status');
          }
        }
        set(() => ({
          entitiesStatuses: next,
          hasChangesThatRequireSave: true,
        }));
      },
      metaDossierInfoIsLoading: true as boolean,
      entitiesRejectionReasons: new Map<string, string>(),
      setEntityRejectionReason: (entityId: string, reason: string) => {
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }
        const prev = get().entitiesRejectionReasons;
        const next = new Map(prev);
        next.set(entityId, reason);
        set(() => ({
          entitiesRejectionReasons: next,
          hasChangesThatRequireSave: true,
        }));
      },
      getEntityRejectionReason: (entityId: string) => {
        return get().entitiesRejectionReasons.get(entityId) || '';
      },
      setMetaDossierInfoIsLoading: (isLoading: boolean) => {
        set(() => ({ metaDossierInfoIsLoading: isLoading }));
      },
      metaDossierInfo: undefined as TMetaDossierResponse | undefined,
      setMetaDossierInfo: (metaDossierInfo: TMetaDossierResponse) => {
        // We also update the metaDossierWipElByMetaPath elements
        const prev = get().metaDossierWipElByMetaPath;
        const next = new Map(prev);

        const metaEntries: TMetaDossierEntry[] = [
          ...Object.values(metaDossierInfo.metaDossierData.benef),
          ...Object.values(metaDossierInfo.metaDossierData.chantier),
          ...Object.values(metaDossierInfo.metaDossierData.company),
          ...Object.values(metaDossierInfo.metaDossierData.operations).flatMap(
            (op) => Object.values(op.data)
          ),
        ];

        for (const metaEntry of metaEntries) {
          if (!next.has(metaEntry.internalPath)) {
            // we init the key
            next.set(metaEntry.internalPath, {
              metaPath: metaEntry.internalPath,
              hasBeenEdited: false,
              label: metaEntry.label,
              type: metaEntry.type,
              value: metaEntry.value,
              remoteValue: metaEntry.value,
              stringOptions: metaEntry.stringOptions,
            });
          } else {
            // we only update the remote value
            next.set(metaEntry.internalPath, {
              ...next.get(metaEntry.internalPath)!,
              remoteValue: metaEntry.value,
            });
          }
        }

        const newMetaPaths = new Set(metaEntries.map((e) => e.internalPath));

        for (const metaPath in [...next.keys()]) {
          if (!newMetaPaths.has(metaPath)) {
            // we clean previous data
            next.delete(metaPath);
          }
        }
        set(() => ({ metaDossierInfo, metaDossierWipElByMetaPath: next }));
      },
      metaDossierWipElByMetaPath: new Map(),
      metaDossierChanges: {},
      setMetaDossierChanges: (
        metaDossierChanges: Record<string, TSyncValue>
      ) => {
        set({ metaDossierChanges });
      },
      setMetaDossierWipEl: (metaPath: string, value: TSyncValue) => {
        console.log('changeRequested', { metaPath, value });
        if (get().isInReadOnlyMode) {
          enqueueSnackbar(i18n.t('rwLock.roNotification'), {
            variant: 'error',
          });
          return;
        }

        const prev = get().metaDossierWipElByMetaPath;
        const next = new Map(prev);

        const info = prev.get(metaPath)!;
        let hasError = false;

        // Errors on other types are not possible at the moment
        // (things are checked input themselves)
        if (info.type === 'email-address') {
          if (value !== null && !z.string().email().safeParse(value).success) {
            hasError = true;
          }
        }

        next.set(metaPath, {
          ...info,
          value,
          hasError,
          hasBeenEdited: true,
        });

        set(() => ({ metaDossierWipElByMetaPath: next }));
      },
      isSavingNewMetaDossierToAbrico: false as boolean,
      saveNewMetaDossierToAbrico: async () => {
        try {
          get().enableReadOnlyMode(true);
          set({ isSavingNewMetaDossierToAbrico: true });

          try {
            enqueueSnackbar(i18n.t('sync.notifications.save.inProgress'), {
              variant: 'info',
            });

            const dossierId = useAppState.getState().getDossierId();
            await updateAbricoMetaDossier({
              dossierId,
              changes: get().metaDossierChanges,
            });
            await queryClient.invalidateQueries({
              queryKey: [getMetaDossierQueryKey(dossierId)],
            });
            enqueueSnackbar(i18n.t('sync.notifications.save.success'), {
              variant: 'success',
            });
          } catch (e) {
            enqueueSnackbar(i18n.t('sync.notifications.save.failed'), {
              variant: 'error',
            });
          }
        } finally {
          get().disableReadOnlyMode();
          set({ isSavingNewMetaDossierToAbrico: false });
        }
      },
    }),
    {
      name: 'interactions-store',
    }
  )
);

useInteractionsState.subscribe((state, prevState) => {
  if (
    state.metaDossierWipElByMetaPath !== prevState.metaDossierWipElByMetaPath
  ) {
    const metaDossierChanges: Record<string, TSyncValue> = {};
    for (const [key, info] of state.metaDossierWipElByMetaPath.entries()) {
      if (info.hasError) {
        continue;
      }
      if (info.value !== info.remoteValue) {
        metaDossierChanges[key] = info.value;
      }
    }

    useInteractionsState.getState().setMetaDossierChanges(metaDossierChanges);
  }

  if (
    state.dossier &&
    prevState.dossier &&
    (state.dossier.workflowStatus !== prevState.dossier.workflowStatus ||
      state.dossier.id !== prevState.dossier.id)
  ) {
    // We refetch the meta dossier data only when a new workflow has run
    queryClient.invalidateQueries({
      queryKey: [getMetaDossierQueryKey(state.dossier.id)],
    });
  }
});
