import { FieldValue } from '@firebase/firestore';
import { z } from 'zod';

export enum WorkflowStatus {
  COMPLETED = 'completed',
  STARTED = 'started',
  FAILED = 'failed',
}

export type Order = 'asc' | 'desc';

export type TranslationT = (
  a: string | string[],
  b?: Record<string, unknown>
) => string;

export interface IEntity {
  confidence: number;
  mentionText: string;
  boundingBox: { x: number; y: number }[];
  pageNumber: number;
  type: string;
  docType?: IDocType;
  docKey?: string;
  propertyType?: string;
  normalizedValue?: string;
  propertiesLength?: number;
  groupKey?: number;
  rules: string[];
}

export interface IEntityForDisplay extends IEntity {
  id: string;
  comparisonId: string;
  transformedText: string;
  label: string;
  kind: 'extracted';
}

export interface IPostProcessedEntity {
  id: string;
  comparisonId: string;
  transformedText: string;
  // Ease common API with IEntity
  mentionText: string;
  type: string;
  docType: IDocType;
  docKey: string;
  label: string;
  kind: 'postProcessed';
  groupKey?: number;
  rules: string[];
}

export interface IMissingEntity {
  id: string;
  comparisonId: string;
  type: string;
  docType: IDocType;
  docKey: string;
  label: string;
  kind: 'missing';
  rules?: string[];
}

export type TFakeMissingEntityGroup = {
  // For a smooth data handling, we put missing fields as a separate 'fake group'
  fakeGroup: true;
  entities: [];
  missingEntities: IMissingEntity[];
};

export type TFakeEntityGroup = {
  // For a smooth data handling, we put simple entities as a separate 'fake group'
  fakeGroup: true;
  entities: [IEntityForDisplay | IPostProcessedEntity];
  missingEntities: [];
};

export type TEntityGroup = {
  // This is a real group of entities
  fakeGroup: false;
  label: string;
  entities: (IEntityForDisplay | IPostProcessedEntity)[];
  missingEntities: IMissingEntity[];
};

export interface IPreparedDocSection {
  sectionName: string;
  missingEntities: IMissingEntity[];
  groups: (TFakeEntityGroup | TEntityGroup)[];
}

export interface IPreparedDocEntities {
  docKey: string;
  docType: IDocType;
  isLastDoc: boolean;
  preparedSection: IPreparedDocSection[];
}

export type IPreparedEntities = {
  docType: IDocType;
  entitiesForDoc: IPreparedDocEntities[];
}[];

export interface IDossier extends DossierDataAtCreation {
  id: string;
  docTypesByFileByPage?: IDocTypesByFileByPage;
  size?: number;
  updatedAt?: FieldValue;
  workflowStatus?: WorkflowStatus;
  workflowExecutions: number;
  disableAutoReorder?: boolean;
  displayedPageNumberByWorkflowPageNumber?: Record<string, number>;
  pdfFileVersion?: number;
  metadataIsFromWorkflow?: boolean;
  archived?: boolean | null;
  archivedAt?: FieldValue | null;
  archivedBy?: string | null;
}

export interface IDossierWithAnalysis extends IDossier {
  analysis: TAnalysisResponse;
}

export enum DossierStatus {
  NEW = 'new',
  IN_PROGRESS = 'inProgress',
  VALID = 'valid',
  REJECTED = 'rejected',
  INVALID = 'invalid',
  INCOMPLETE = 'incomplete',
}

export interface DossierDataAtCreation {
  name: string;
  companyId: string;
  createdBy: string;
  createdAt: FieldValue;
  isUniqueDevis: boolean;
  tagId: string;
  status: DossierStatus;
}

export interface IDossierLock {
  dossierId: string;
  kickHistory?: {
    email: string;
    uid: string;
    rwOwnershipStartedAt: FieldValue;
  }[];
  owner?: { email: string; uid: string };
  viewId?: string;
  createdAt?: FieldValue;
}

export interface IFileInfo {
  name: string;
  url: string;
  storageUri: string;
  size: number;
  timeCreated: string;
  updated?: string;
  uploadedBy: string;
}

export enum IDocType {
  DEVIS = 'devis',
  DEVIS_NON_SIGNE = 'devis_ns',
  FACTURE = 'facture',
  AVIS_IMPOSITION = 'avis_imposition',
  AUTRE = 'autre',
  ATTESTATION_RGE = 'attestation_rge',
  ATTESTATION_SUR_HONNEUR = 'attestation_sur_honneur',
  CADRE_CONTRIBUTION = 'cadre_contribution',
  CNI = 'CNI',
  NOTE_DIMENSIONNEMENT = 'note_dimensionnement',
}

export type IDocTypesByFileByPage = {
  [K in IDocType]?: {
    [fileName: string]: number[];
  };
};

export const CompanyZod = z.object({
  id: z.string(),
  name: z.string(),
  members: z.array(z.string()).default([]),
  admin: z.array(z.string()).default([]),
  featureFlags: z
    .object({
      handleMissingFields: z.boolean().default(false),
      handleFieldRules: z.boolean().default(false),
      handleBackofficeSync: z.boolean().default(false),
    })
    .default({}),
  logo: z
    .object({
      url: z.string(),
      storageUri: z.string().optional(),
    })
    .optional(),
});

export type ICompany = z.infer<typeof CompanyZod>;

export enum TagRole {
  DEFAULT = 'default',
}

export interface ITag {
  id: string;
  name: string;
  companyId: string;
  role?: TagRole;
  hideForUser?: string[];
}

export type ISeverity = 'success' | 'error' | 'info' | 'warning';

export type IError = {
  severity: ISeverity;
  code: string;
};

export type IVerificationSignatureResult = {
  signed: boolean;
  signer: string;
  signerName: string;
  signTime: any;
  verificationStatus: number;
  documentStatus: number;
  digestStatus: number;
  trustStatus: number;
  permissionStatus: number;
  disallowedChanges: { objnum: number; type: string }[];
  trustVerificationResultBoolean: boolean;
  trustVerificationResultString: string;
  timeOfTrustVerificationEnum: number;
  trustVerificationTime: any;
  id: number;
  badgeIcon: string;
  validSignerIdentity: boolean;
  digestAlgorithm: string;
  documentPermission: number;
  isCertification: boolean;
  contactInfo: string;
  location: string;
  reason: string;
  issuerField: string;
  subjectField: string;
  validAtTimeOfSigning: boolean;
  formatedSignTime: Date;
};

export enum AnnotStatus {
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export type TEntityVersionInfo =
  | {
      kind: 'extracted' | 'postProcessed';
      transformedText: string;
      normalizedTransformedText: string;
      status: AnnotStatus;
      rejectedReason?: string;
    }
  | {
      kind: 'missing';
      status: AnnotStatus;
      rejectedReason?: string;
    };

export type TInteractionsFS = {
  dossierId?: string;
  entitiesStatuses: Record<string, AnnotStatus>;
  entitiesRejectionReasons?: Record<string, string>;
  prevVersion?: {
    dossierId: string;
    // string is the comparisonId here
    data: Record<string, TEntityVersionInfo>;
  };
};

export type TGroupDef = { fields: string[]; label: string };

export type TSectionsRes = {
  [K in IDocType]?: {
    readonly label: string;
    readonly fields: (string | TGroupDef)[];
  }[];
};

export type TMissingFieldRes = {
  kind: 'missing';
  docType: string;
  docKey: string;
  type: string;
  label: string;
  rules: string[];
  comparisonId: string;
  id: string;
};

export type TMissingFieldsRes = {
  [k in IDocType]?: Record<string, TMissingFieldRes[]>;
};

export type TAnalysisResponse = {
  sections: TSectionsRes;
  missingFields: TMissingFieldsRes;
  extractedEntities: IEntityForDisplay[];
  postProcessedEntities: IPostProcessedEntity[];
  // Information that will not need (async) post processing
  baseMetaDossierInfo?: TBaseMetaDossierInfo;
};

// We might support other "string" types in the future.
export type TSyncableTypes =
  | 'string'
  | 'number'
  | 'boolean'
  | 'date-time'
  | 'email-address';

export type TBaseMetaDossierInfo = {
  name: string;
};

export type TSyncValue = string | number | boolean | null;

export type TMetaDossierEntry = {
  metaKey: string;
  value: TSyncValue;
  label: string;
  type: TSyncableTypes;
  internalPath: string;
  stringOptions?: readonly [string, ...string[]];
};

export type TMetaDossierData = {
  chantier: Record<string, TMetaDossierEntry>;
  benef: Record<string, TMetaDossierEntry>;
  company: Record<string, TMetaDossierEntry>;
  operations: Record<
    string,
    { codeAdeme: string; data: Record<string, TMetaDossierEntry> }
  >;
};

export type TMetaDossierByAnnotIdValue = {
  metaDossierDataPath: string;
  annotId: string;
  computedIsEqual: boolean;
  _original: {
    originalMetaDossierValue: TSyncValue;
    originalAnnotTransformedText: string;
  };
};

export type TMetaDossierResponse = {
  baseMetaDossierInfo: TBaseMetaDossierInfo;
  metaDossierData: TMetaDossierData;
  metaDossierLinkByAnnotId: Record<string, TMetaDossierByAnnotIdValue[]>;
  review: {
    reviewed: boolean | null;
    isValid: boolean | null;
  };
};

export type TWipMetaEl = {
  metaPath: string;
  hasBeenEdited: boolean;
  type: TSyncableTypes;
  value: TSyncValue;
  label: string;
  remoteValue: TSyncValue;
  hasError?: boolean;
  stringOptions?: readonly string[];
};

export type TMetaDossierWipElByMetaPath = Map<string, TWipMetaEl>;

export type TReviewInfo = {
  stats: { [k in AnnotStatus]: number };
  rejectedEntities: {
    id: string;
    label: string;
    status: AnnotStatus;
    rejectionReason: string;
    kind: 'postProcessed' | 'missing' | 'extracted';
  }[];
};

export const ZGenerateReviewFeedbackBody = z.object({
  dossierId: z.string(),
  rejections: z.array(
    z.object({
      label: z.string(),
      rejectionReason: z.string(),
      isMissing: z.boolean().default(false),
    })
  ),
});

export const ZSaveReviewFeedbackBody = z.object({
  dossierId: z.string(),
  isValid: z.boolean(),
  reviewFeedbackMarkdown: z.string().default(''),
  notifyUploader: z.boolean().default(false),
  reviewerName: z.string(),
});
