import CancelIcon from '@mui/icons-material/Cancel';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Badge } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { MutableRefObject, useCallback, useMemo } from 'react';

import EntityCard from 'components/Entity/EntityCard';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { usePersistedStore } from 'stores/persistedStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import {
  AnnotStatus,
  IMissingEntity,
  TEntityGroup,
  TFakeEntityGroup,
  TFakeMissingEntityGroup,
} from 'types/index';

type Props = {
  groups: (TFakeEntityGroup | TEntityGroup)[];
  missingEntities: IMissingEntity[];
  docType: string;
  docKey: string;
  sectionName: string;
  scrollOffset: number;
  entityGlobalAutoSelectRef: MutableRefObject<() => void>;
};

const DocSectionEntities = ({
  groups,
  missingEntities,
  sectionName,
  scrollOffset,
  entityGlobalAutoSelectRef,
  docKey,
}: Props) => {
  const company = useAppState((state) => state.company);
  const hideReviewedEntitiesSettings = usePersistedStore(
    (state) => state.hideReviewedEntitiesSettings
  );

  const entitiesStatuses = useInteractionsState(
    (state) => state.entitiesStatuses
  );
  const visibleKey = `${docKey}|${sectionName}`;

  const isForcedVisible = useUiState((state) =>
    state.forcedShownSections.has(visibleKey)
  );
  const handleToggleForceVisible = useCallback(() => {
    if (!hideReviewedEntitiesSettings) return;

    useUiState.getState().toggleForcedShownSection(visibleKey);
  }, [hideReviewedEntitiesSettings, visibleKey]);

  const mergedGroups = useMemo(() => {
    const out: (TFakeMissingEntityGroup | TFakeEntityGroup | TEntityGroup)[] = [
      ...groups,
    ];
    if (missingEntities.length > 0) {
      out.unshift({
        missingEntities,
        entities: [],
        fakeGroup: true,
      } as TFakeMissingEntityGroup);
    }
    return out;
  }, [groups, missingEntities]);

  const statusCounts = useMemo(() => {
    const allEntities = mergedGroups
      .map((el) => [...el.entities, ...el.missingEntities])
      .flat()
      .filter(
        (entity) =>
          entity.kind !== 'missing' || company?.featureFlags.handleMissingFields
      );

    return allEntities.reduce(
      (acc, entity) => {
        acc[entitiesStatuses.get(entity.id)!]++;
        return acc;
      },
      {
        [AnnotStatus.VALIDATED]: 0,
        [AnnotStatus.REJECTED]: 0,
        [AnnotStatus.PENDING]: 0,
      }
    );
  }, [entitiesStatuses, mergedGroups, company]);

  const hasNonPendingEntities = useMemo(() => {
    return Object.entries(statusCounts)
      .filter(([k]) => k !== AnnotStatus.PENDING)
      .some(([, count]) => count > 0);
  }, [statusCounts]);

  return (
    <Stack
      key={sectionName}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      sx={{ width: '100%' }}
      marginBottom={'15px'}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={2}
        sx={{ cursor: hideReviewedEntitiesSettings ? 'pointer' : 'unset' }}
        onClick={handleToggleForceVisible}
      >
        <Typography
          level="body-sm"
          sx={{ fontWeight: '600' }}
          style={{
            color: 'var(--joy-palette-text-primary)',
          }}
        >
          {sectionName}
        </Typography>
        {hideReviewedEntitiesSettings && (
          <Stack gap={2} direction={'row'}>
            <Badge
              badgeContent={statusCounts[AnnotStatus.REJECTED]}
              color={'danger'}
              size={'sm'}
            >
              <CancelIcon sx={{ fontSize: '1.2rem' }} />
            </Badge>
            <Badge
              badgeContent={statusCounts[AnnotStatus.VALIDATED]}
              color={'success'}
              size={'sm'}
            >
              <CheckRoundedIcon sx={{ fontSize: '1.2rem' }} />
            </Badge>
            {hasNonPendingEntities &&
              hideReviewedEntitiesSettings &&
              (isForcedVisible ? (
                <VisibilityOffIcon sx={{ ml: 1 }} />
              ) : (
                <VisibilityIcon sx={{ ml: 1 }} />
              ))}
          </Stack>
        )}
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width={'100%'}
      >
        {mergedGroups
          .filter(
            (group) =>
              company?.featureFlags.handleMissingFields ||
              group.entities.length > 0
          )
          .map((group, idx) => (
            <Stack
              key={idx}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              width={'100%'}
              sx={{
                borderLeft: !group.fakeGroup
                  ? '2px solid var(--joy-palette-text-primary)'
                  : 'unset',
                marginBottom: !group.fakeGroup ? 2 : '0',
                paddingLeft: !group.fakeGroup ? 1 : '0',
                borderRadius: 5,
              }}
            >
              {!group.fakeGroup && (
                <Typography level="body-sm">{group.label}</Typography>
              )}
              {[...group.entities, ...group.missingEntities]
                .filter(
                  (entity) =>
                    company?.featureFlags.handleMissingFields ||
                    entity.kind !== 'missing'
                )
                .map((entity) => (
                  <EntityCard
                    key={entity.id}
                    entity={entity}
                    scrollOffset={scrollOffset}
                    entityGlobalAutoSelectRef={entityGlobalAutoSelectRef}
                    isForcedVisible={isForcedVisible}
                  />
                ))}
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default DocSectionEntities;
