import { serverTimestamp } from '@firebase/firestore';
import Bowser from 'bowser';
import { addDoc, collection } from 'firebase/firestore';

import { db } from 'config/firebaseInit.ts';
import { useAppState } from 'stores/appStore.ts';

export const logDurationInFs = async ({
  durationMs,
  name,
}: {
  durationMs: number;
  name: string;
}) => {
  const extraInfo: {
    dossierId?: string;
    userEmail?: string;
    companyId?: string;
  } = {};
  const urlParams = useAppState.getState().urlParams;
  if (urlParams.dossierId) {
    extraInfo.dossierId = urlParams.dossierId;
  }
  if (useAppState.getState().user?.email) {
    extraInfo.userEmail = useAppState.getState().user!.email!;
  }
  if (useAppState.getState().company?.id) {
    extraInfo.companyId = useAppState.getState().company!.id!;
  }

  const payload = {
    origin: 'station',
    name,
    durationMs,
    ...extraInfo,
  };
  await addDoc(collection(db, 'timings'), {
    ...payload,
    browser: Bowser.getParser(window.navigator.userAgent).getResult(),
    createdAt: serverTimestamp(),
  });
  console.debug('Logged timing:', payload);
};
