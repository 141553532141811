import { useCallback, useEffect, useState } from 'react';

import WebViewerWrapper from 'components/WebViewer/WebViewerWrapper';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { reorderDocumentPages } from 'utils/reorderDocumentPages.ts';
import { logDurationInFs } from 'utils/timings.ts';

const YourCustomPdfViewer = () => {
  const company = useAppState((state) => state.company);
  const instance = useInteractionsState((state) => state.instance);
  const dossier = useInteractionsState((state) => state.dossier);

  const [initHasRun, setInitHasRun] = useState(false);

  const initView = useCallback(async () => {
    if (initHasRun || !dossier) return;
    setInitHasRun(true);

    const { Core, UI } = instance;
    const { PDFNet } = Core;
    const { openElements, closeElements, loadDocument } = UI;

    useAppState
      .getState()
      .downloadViewedPdf(dossier)
      .then(async (rawPdfBytes) => {
        const rawPdfDocument = await PDFNet.PDFDoc.createFromBuffer(
          Uint8Array.from(rawPdfBytes)
        );
        let reorderedPdf = rawPdfDocument;
        if (!dossier.disableAutoReorder) {
          reorderedPdf = await reorderDocumentPages(
            rawPdfDocument,
            dossier.pageNumbersMappingForReordering!,
            instance
          );
        }

        loadDocument(reorderedPdf, {
          filename: dossier.fileName,
          extension: 'pdf',
          enableOfficeEditing: false,
        });

        if (dossier.nbDocuments > 1) {
          openElements(['outlinesPanel']);
        } else {
          closeElements(['outlinesPanel']);
        }

        // We have made the critical changes to the document, we can move on
        useUiState.getState().setViewerIsReady(true);
        logDurationInFs({
          durationMs: performance.now(),
          name: 'Ready for first interactions',
        });
      });
  }, [initHasRun, instance, dossier]);

  useEffect(() => {
    if (instance && dossier && !initHasRun) initView();
  }, [instance, initHasRun, initView, dossier]);

  if (!company?.id) return <p> Please sign in with your professional email </p>;
  return (
    <div
      className="webWivewerWrapper"
      style={{ height: '100vh', width: '100%' }}
    >
      <WebViewerWrapper />
    </div>
  );
};

export default YourCustomPdfViewer;
