import Bowser from 'bowser';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import { db } from 'config/firebaseInit';
import { useAppState } from 'stores/appStore.ts';

export const createDossierSession = async () => {
  const dossierId = useAppState.getState().getDossierId();
  const companyId = useAppState.getState().company!.id;
  const userEmail = useAppState.getState().user!.email!;

  const dossierSessionsRef = collection(db, 'dossierSessions');
  return await addDoc(dossierSessionsRef, {
    dossierId,
    userEmail,
    startTime: serverTimestamp(),
    companyId,
    browser: Bowser.getParser(window.navigator.userAgent).getResult(),
  });
};
