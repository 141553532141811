import {
  KeyboardBackspaceRounded,
  WarningAmberRounded,
} from '@mui/icons-material';
import { Alert, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { Core } from '@pdftron/webviewer';
import {
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { NavLink } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import FullScreenLoader from './FullScreenLoader.tsx';
import PDFViewer from './PDFviewer';

import {
  getAnalysis,
  invokeSplitterAndExtractorWorkflow,
  reloadMetaDossier,
} from 'cloudFunctions/functions';
import EntitiesPanel from 'components/Entity/EntitiesPanel.tsx';
import { ReviewPanel } from 'components/Review/ReviewPanel.tsx';
import { ReviewStatus } from 'components/Review/ReviewStatus.tsx';
import { SyncPanel } from 'components/Sync/SyncPanel.tsx';
import ReadOnlyModal from 'components/UI/ReadOnlyModal.tsx';
import UserFeedbackModal from 'components/UI/UserFeedbackModal';
import { TrackTiming } from 'components/Utils/TrackTiming.tsx';
import firebaseConfig from 'config/firebaseConfig.ts';
import {
  getDossierById,
  getDossierQueryKey,
  getFSDossierInteractions,
  getMetaDossierQueryKey,
  onDossiersUpdated,
} from 'queries/dossiers';
import { createDossierSession } from 'queries/dossierSessions.ts';
import { tryAcquireRWLockAndMonitor } from 'queries/dossiersRWLocks.ts';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { IDossier, TAnalysisResponse, WorkflowStatus } from 'types/index';
import { updateOutlineSelected } from 'utils/bookmarks.ts';
import { ABRICO_ORANGE_MAIN } from 'utils/constants.ts';
import { rescalePagesWidthToA4 } from 'utils/document.ts';
import { Dossier } from 'utils/dossier.ts';
import { saveDocumentToCloud } from 'utils/file.ts';
import {
  InteractionsMode,
  interactionsState,
  setAutoScrollFromDocumentViewer,
  setFocusEntityFromDocumentViewer,
} from 'utils/interactions.ts';
import { startPageNumberAtOne } from 'utils/processProcessorsOutput';

const dossierIdWithDataQuery = () => {
  const dossierId = useAppState.getState().getDossierId();

  const queryFunction = async () => {
    const dossierData = await getDossierById(dossierId);
    // We don't make this await to avoid blocking IO, but things should load in time
    getFSDossierInteractions(dossierData.id).then((interactions) =>
      useInteractionsState.getState().mergeData(interactions)
    );
    let analysis: TAnalysisResponse = {
      sections: {},
      extractedEntities: [],
      missingFields: {},
      postProcessedEntities: [],
    };
    if (dossierData.workflowStatus === WorkflowStatus.COMPLETED) {
      try {
        const { data } = await getAnalysis({ dossierId: dossierData.id });
        analysis = data;
      } catch (e) {
        console.error('Failed to load analysis: ', e);
      }
    }

    const docTypesByFileByPage =
      dossierData?.docTypesByFileByPage &&
      dossierData.workflowStatus === WorkflowStatus.COMPLETED
        ? startPageNumberAtOne(dossierData?.docTypesByFileByPage)
        : {};

    const dossier = new Dossier({
      ...dossierData,
      workflowExecutions: dossierData?.workflowExecutions ?? 0,
      docTypesByFileByPage,
      analysis,
    });

    useInteractionsState.getState().setDossier(dossier);
    return dossier;
  };

  return queryOptions({
    queryKey: [getDossierQueryKey(dossierId)],
    queryFn: queryFunction,
    staleTime: Infinity,
  });
};

const Station = () => {
  const rootElem: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const instance = useInteractionsState((state) => state.instance);
  const { user, company } = useAppState(
    useShallow((state) => ({ user: state.user, company: state.company }))
  );
  const [isWorkflowLoading, setIsWorkflowLoading] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string>('');
  const entityGlobalAutoSelectRef = useRef(() => {});

  const viewerIsReady = useUiState((state) => state.viewerIsReady);
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const hasChangesThatRequireSave = useInteractionsState(
    (state) => state.hasChangesThatRequireSave
  );
  const metaDossierInfoIsLoading = useInteractionsState(
    (state) => state.metaDossierInfoIsLoading
  );

  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const { data: dossier } = useQuery(dossierIdWithDataQuery());

  useEffect(() => {
    if (!dossier) return;

    document.title = dossier.name;
    dossier.refreshUI();
  }, [dossier]);

  const callEntityGlobalAutoSelectRef = useCallback(() => {
    interactionsState.mode = InteractionsMode.FROM_PANEL_TO_PDF;
    entityGlobalAutoSelectRef.current();
  }, []);

  const [initHasRun, setInitHasRun] = useState(false);
  useEffect(() => {
    if (initHasRun) return;
    if (!(instance && dossier && user)) return;
    setInitHasRun(true);

    tryAcquireRWLockAndMonitor(useAppState.getState().getDossierId(), user!);

    createDossierSession().then((ref) => setSessionId(ref.id));

    const { documentViewer } = instance.Core;
    documentViewer.addEventListener('documentLoaded', async () => {
      const changesHistory: any[] = [
        {
          start: true,
          entitiesPageNumbersTracker: dossier.entitiesPageNumbersTrackerCopy,
          nbPages: instance.Core.documentViewer.getDocument().getPageCount(),
          dossierInitializedAt: dossier._initalizedAt,
        },
      ];

      documentViewer.addEventListener(
        'pagesUpdated',
        async (changes: Core.DocumentViewer.pagesUpdatedChanges) => {
          if (changes.linearizedUpdate) {
            return;
          }

          const dossier = await useInteractionsState.getState().getDossier();

          const prevEntitiesPageNumbersTracker =
            dossier.entitiesPageNumbersTrackerCopy;
          const hasChanges = await dossier.trackPdfChanges(changes);
          if (hasChanges) {
            useInteractionsState.getState().setHasChangesThatRequireSave(true);
          }

          // We keep track of potential errors in changes handling, we still seem to have bugs around that
          const nbPages = instance.Core.documentViewer
            .getDocument()
            .getPageCount();

          changesHistory.push({
            changes,
            prevEntitiesPageNumbersTracker,
            entitiesPageNumbersTracker: dossier.entitiesPageNumbersTrackerCopy,
            nbPages,
            dossierInitializedAt: dossier._initalizedAt,
          });

          if (
            Object.values(
              dossier.updatedDisplayedPageNumberByWorkflowPageNumber
            ).some((v) => v !== null && v > nbPages)
          ) {
            console.warn(
              `Page tracking issues context: ${JSON.stringify({
                changesHistory,
              })}`
            );
            console.error(
              `Page tracking failed at some point. Check what happened.`
            );
          }

          if (changes.added.length > 0) {
            const updatedDoc = documentViewer.getDocument();
            const doc = await updatedDoc.getPDFDoc();
            await instance.Core.PDFNet.runWithCleanup(
              async () => await rescalePagesWidthToA4(doc, changes.added),
              import.meta.env.VITE_APRYSE_KEY
            );
            // We refresh the viewer to avoid visual glitches and to apply the document changes
            // Note that the updatedDoc.rotatePages of 0 is a HACK. Sorry I haven't found
            // another WORKING way to properly refresh the viewer lol.
            await updatedDoc.rotatePages(changes.added, 0);
            // This helps to remove rendering glitches
            documentViewer.refreshAll();
            documentViewer.updateView();
          }
        }
      );
      setAutoScrollFromDocumentViewer(documentViewer, rootElem.current!);
      setFocusEntityFromDocumentViewer(instance);

      documentViewer.addEventListener(
        'pageNumberUpdated',
        async (pageNumber: number) => {
          await updateOutlineSelected(documentViewer.getDocument(), pageNumber);
        }
      );

      if (company?.featureFlags?.handleBackofficeSync) {
        console.time('Reloading meta dossier');
        useInteractionsState.getState().setMetaDossierInfoIsLoading(true);
        reloadMetaDossier({ dossierId: dossier.id }).then(() => {
          console.timeEnd('Reloading meta dossier');
          queryClient.invalidateQueries({
            queryKey: [getMetaDossierQueryKey(dossier.id)],
          });
        });
      }
    });
  }, [
    instance,
    user,
    initHasRun,
    t,
    dossier,
    company?.featureFlags?.handleBackofficeSync,
    queryClient,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasChangesThatRequireSave) {
        e.preventDefault();
      }

      // We handle the closing of the session through navigator.sendBeacon and a remote CF
      // all other methods (doing the FS modifications client side) have proven to be unreliable, unfortunately.
      const body = {
        dossierId: useAppState.getState().getDossierId(),
        sessionId: sessionId ?? '',
        releaseRwLock: !useInteractionsState.getState().isInReadOnlyMode,
      };

      const url = `https://europe-west9-${firebaseConfig.projectId}.cloudfunctions.net/stationHelpers-closeSession`;

      navigator.sendBeacon(
        url,
        // We send the data as plain text to avoid CORS issues https://stackoverflow.com/a/44142982
        JSON.stringify(body)
      );
    };

    // This seems to work, digging into https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
    // didn't output more valuable information
    // visibilityChange is not triggered when closing the tab
    window.addEventListener('beforeunload', handleBeforeUnload);

    // we clean our event listener
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [company, hasChangesThatRequireSave, user, sessionId]);

  const handleTriggerWorkflow = useCallback(async () => {
    const dossierId = useAppState.getState().getDossierId();
    setIsWorkflowLoading(true);
    try {
      await saveDocumentToCloud({
        notify: false,
      });
      useInteractionsState.getState().enableReadOnlyMode(true);
      console.time(`workflow processing file: `);
      await invokeSplitterAndExtractorWorkflow({
        dossierId,
      })
        .then(
          async () => {
            console.timeEnd(`workflow processing file: `);
            const dossierQueryKey = getDossierQueryKey(dossierId);
            await queryClient.invalidateQueries({
              queryKey: [dossierQueryKey],
            });
          },
          (error) => console.error(error)
        )
        .catch((err: any) => {
          console.error('Error in invoking workflow:', err);
          console.timeEnd(`workflow processing file: `);
        });
    } finally {
      useInteractionsState.getState().disableReadOnlyMode();
      setIsWorkflowLoading(false);
    }
  }, [queryClient]);

  return (
    <>
      <ReadOnlyModal></ReadOnlyModal>
      {!viewerIsReady && <FullScreenLoader></FullScreenLoader>}
      <PanelGroup
        direction="horizontal"
        autoSaveId="persistence"
        style={{ width: '100vw', backgroundColor: '#16181C' }}
      >
        <Panel>
          <PDFViewer />
        </Panel>
        <PanelResizeHandle style={{ width: '6px' }} />
        <Panel
          defaultSize={30}
          minSize={5}
          style={{ containerType: 'inline-size' }}
        >
          <Box
            sx={{
              height: '100vh',
              width: '100%',
              borderLeft: `dotted 2px #ffae95`,
              '@container (max-width: 330px)': {
                borderLeft: 'none',
                overflow: 'hidden',
                animation: 'glow .8s infinite alternate',
                '@keyframes glow': {
                  from: {
                    boxShadow: `inset 8px 0 ${ABRICO_ORANGE_MAIN}`,
                  },
                  to: {
                    boxShadow: `inset 4px 0 #ffae95`,
                  },
                },
              },
            }}
          >
            <Stack
              ref={rootElem}
              direction="column"
              alignItems="center"
              spacing={0}
              sx={{
                height: '100%',
                width: '100%',
                '@container (max-width: 330px)': {
                  display: 'none',
                },
              }}
            >
              <Tabs
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: 'auto',
                  width: '100%',
                  // https://stackoverflow.com/a/14964944
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: 0,
                }}
              >
                <TabList tabFlex={1}>
                  <Tab variant="plain" color="neutral">
                    {t('ui.tabs.entities')}
                  </Tab>
                  {company?.featureFlags.handleBackofficeSync && (
                    <>
                      <Tab
                        variant="plain"
                        color="neutral"
                        sx={{ textOverflow: 'ellipsis' }}
                      >
                        <Stack direction="row" alignItems="center" gap={1}>
                          {t('ui.tabs.review')}
                          <ReviewStatus></ReviewStatus>
                        </Stack>
                      </Tab>
                      <Tab
                        variant="plain"
                        color="neutral"
                        sx={{ textOverflow: 'ellipsis' }}
                      >
                        {t('ui.tabs.sync')}{' '}
                        {dossier?.analysis?.baseMetaDossierInfo?.name ?? ''}
                        {metaDossierInfoIsLoading && (
                          <CircularProgress
                            sx={{ ml: 1 }}
                            size={'sm'}
                          ></CircularProgress>
                        )}
                      </Tab>
                    </>
                  )}
                </TabList>
                <TabPanel
                  value={0}
                  keepMounted={true}
                  sx={{
                    width: '100%',
                    overflow: 'auto',
                    height: '100%',
                  }}
                >
                  <Stack
                    direction="column"
                    onMouseEnter={() => {
                      interactionsState.mode =
                        InteractionsMode.FROM_PANEL_TO_PDF;
                      // We automatically refocus the panel to be able to use the keyboard
                      panelRef?.current?.focus();
                    }}
                    onMouseMove={callEntityGlobalAutoSelectRef}
                    onMouseLeave={() => {
                      interactionsState.mode =
                        InteractionsMode.FROM_PDF_TO_PANEL;
                    }}
                    sx={{
                      userSelect: 'none',
                      height: '100%',
                    }}
                  >
                    <Box sx={{ overflowY: 'auto', flex: '1' }}>
                      {(dossier?.workflowStatus ?? null) ===
                        WorkflowStatus.FAILED && (
                        <Alert
                          startDecorator={
                            <WarningAmberRounded fontSize="inherit" />
                          }
                          variant={'solid'}
                          color={'neutral'}
                          sx={{ margin: 4, whiteSpace: 'pre-wrap' }}
                        >
                          {t('workflow.failed')}
                        </Alert>
                      )}
                      <EntitiesPanel
                        dossier={dossier}
                        panelRef={panelRef}
                        entityGlobalAutoSelectRef={entityGlobalAutoSelectRef}
                      ></EntitiesPanel>
                    </Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      flexGrow={0}
                      flexShrink={1}
                      flexBasis="auto"
                      sx={{
                        backgroundColor: '#16181C',
                      }}
                    >
                      <Button
                        onClick={handleTriggerWorkflow}
                        variant="outlined"
                        color="primary"
                        loading={isWorkflowLoading}
                        sx={{
                          margin: '1rem',
                          width: '100%',
                          maxWidth: '300px',
                        }}
                        disabled={
                          isWorkflowLoading ||
                          (!dossier ? true : dossier.workflowExecutions >= 2) ||
                          isInReadOnlyMode
                        }
                      >
                        {isWorkflowLoading
                          ? t('workflow.loading')
                          : t('workflow.start')}
                      </Button>
                      <Button
                        onClick={() => setFeedbackModalOpen(true)}
                        variant="outlined"
                        color="primary"
                        sx={{
                          margin: '1rem',
                          width: '100%',
                          maxWidth: '300px',
                        }}
                      >
                        {t('userFeedback.title')}
                      </Button>
                    </Stack>
                  </Stack>
                </TabPanel>
                {company?.featureFlags.handleBackofficeSync && (
                  <>
                    <TabPanel
                      value={1}
                      keepMounted={false}
                      sx={{
                        width: '100%',
                        overflow: 'auto',
                        height: '100%',
                      }}
                    >
                      {dossier?.id && <ReviewPanel></ReviewPanel>}
                    </TabPanel>
                    <TabPanel
                      value={2}
                      keepMounted={true}
                      sx={{
                        width: '100%',
                        overflow: 'auto',
                        height: '100%',
                      }}
                    >
                      {dossier?.id && <SyncPanel></SyncPanel>}
                    </TabPanel>
                  </>
                )}
              </Tabs>
            </Stack>
            <Stack
              direction="column"
              alignContent={'center'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                height: '100%',
                '@container (min-width: 330px)': {
                  display: 'none',
                },
                margin: 1,
              }}
            >
              <Typography>{t('ui.enlargePanelMessage')}</Typography>
              <div style={{ position: 'relative' }}>
                <KeyboardBackspaceRounded
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    animation: 'glow2 .8s infinite alternate',
                    '@keyframes glow2': {
                      from: {
                        color: ABRICO_ORANGE_MAIN,
                        fontSize: '45px',
                      },
                      to: {
                        color: `#ffae95`,
                        fontSize: '40px',
                      },
                    },
                  }}
                />
              </div>
            </Stack>
          </Box>
        </Panel>
      </PanelGroup>
      {dossier && (
        <UserFeedbackModal
          open={isFeedbackModalOpen}
          onClose={() => setFeedbackModalOpen(false)}
          docTypes={Object.keys(dossier.remappedDocTypesByFileByPage)}
        />
      )}

      <TrackTiming name={'Station Loaded'}></TrackTiming>
    </>
  );
};

const dossierQuery = () => {
  const dossierId = useAppState.getState().getDossierId();

  const queryFunction = () =>
    getDossierById(dossierId).then(async (dossierData) => {
      return dossierData;
    });

  return queryOptions({
    queryKey: [getDossierQueryKey(dossierId) + '-init'],
    queryFn: queryFunction,
    staleTime: Infinity,
  });
};

const StationWithWebviewerInstance = () => {
  const { t } = useTranslation('common');

  const { data, error } = useSuspenseQuery(dossierQuery());

  useEffect(() => {
    useAppState.getState().downloadViewedPdf(data);
  }, [data]);

  // We make sure that the dossier hasn't a running workflow to avoid inconsistent data
  const [canShowStation, setCanShowStation] = useState<boolean>(
    data?.workflowStatus === WorkflowStatus.COMPLETED ||
      data?.workflowStatus === WorkflowStatus.FAILED
  );

  const [unsubscribe, setUnsubscribe] = useState<(() => void) | null>(null);
  useEffect(() => {
    if (!canShowStation && unsubscribe === null && data) {
      const newUnsubscribe = onDossiersUpdated([data.id], (changes) => {
        // and check for relevant changes to the dossiers that where not completed before
        for (const change of changes) {
          const dossier = {
            ...change.doc.data(),
            id: change.doc.id,
          } as IDossier;
          if (
            dossier.workflowStatus === WorkflowStatus.COMPLETED ||
            dossier.workflowStatus === WorkflowStatus.FAILED
          ) {
            setCanShowStation(true);
            // we unsubscribe and invalidate the queries to refresh the cache
            newUnsubscribe();
            break;
          }
        }
      });
      setUnsubscribe(() => newUnsubscribe);
    }
  }, [setCanShowStation, canShowStation, data?.id, unsubscribe, data]);

  if (!data || error) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Typography
            sx={{ mt: 4, padding: 2, borderRadius: 8 }}
            variant={'soft'}
            level={'title-lg'}
            whiteSpace={'pre-wrap'}
            textAlign={'center'}
          >
            {t('dossier.notFound')}
          </Typography>
          <NavLink to={`/`} style={{ textDecoration: 'none' }}>
            <Button sx={{ mt: 2 }}>{t('home.goHome')}</Button>
          </NavLink>
        </Box>
      </>
    );
  }

  return (
    <>
      {canShowStation ? (
        <Station />
      ) : (
        <FullScreenLoader message={t('workflow.loadingDetailed')} />
      )}
    </>
  );
};

export default StationWithWebviewerInstance;
