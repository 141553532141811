import { Core, WebViewerInstance } from '@pdftron/webviewer';

import { useUiState } from 'stores/uiStore.ts';
import { ABRICO_ANNOTATION_PREFIX } from 'utils/constants.ts';

export const checkElementIsVisible = (
  elm: Element,
  offset: number = 0
): boolean => {
  const rect = elm.getBoundingClientRect();
  // @ts-ignore
  if (!elm.checkVisibility({ opacityProperty: true })) return false;

  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );

  return rect.bottom + offset < viewHeight && rect.top - offset > 0;
};

export enum InteractionsMode {
  FROM_PDF_TO_PANEL = 'FROM_PDF_TO_PANEL',
  FROM_PANEL_TO_PDF = 'FROM_PANEL_TO_PDF',
}

// FIXME: refactor this in a more global state maybe, in the meantime this will work
// at the moment we wrap this in the component so that we can modify it from there
// this is ABSOLUTELY needed to avoid conflicts between the different auto interactions
export const interactionsState: {
  mode: InteractionsMode;
  isScrolling: boolean;
} = {
  mode: InteractionsMode.FROM_PDF_TO_PANEL,
  isScrolling: false,
};

let onScrollEnd: (() => void) | null = null;

export const setAutoScrollFromDocumentViewer = (
  documentViewer: Core.DocumentViewer,
  rootElem: HTMLElement
) => {
  // We track the scrolling of the viewer for later use
  const scrollingElement = documentViewer.getScrollViewElement();
  scrollingElement.addEventListener(
    'scroll',
    () => (interactionsState.isScrolling = true)
  );
  scrollingElement.addEventListener(
    'scrollend',
    () => (interactionsState.isScrolling = false)
  );

  documentViewer.addEventListener('pageNumberUpdated', async (pageNumber) => {
    if (interactionsState.mode === InteractionsMode.FROM_PANEL_TO_PDF) return;

    // When the page displayed changes (basically), we scroll to the first corresponding entity card we can find.
    // We indeed have added some metadata to the entity cards to know from the HTML to which page they are linked.
    const entityCards = rootElem.getElementsByClassName('entity-card');
    for (const elem of entityCards) {
      if (
        parseInt(
          elem.attributes.getNamedItem('data-target-page')!.value,
          10
        ) === pageNumber
      ) {
        // To support Google Chrome, we need to scroll to the element only
        // after the scrolling on the PDF document has really ended.
        if (onScrollEnd) {
          scrollingElement.removeEventListener('scrollend', onScrollEnd);
        }
        onScrollEnd = () => {
          useUiState
            .getState()
            .setFocusedEntityId(
              elem.attributes.getNamedItem('data-entity-id')!.value
            );
          scrollingElement.removeEventListener('scrollend', onScrollEnd!);
        };
        if (interactionsState.isScrolling) {
          scrollingElement.addEventListener('scrollend', onScrollEnd!);
        } else {
          onScrollEnd();
        }
        return;
      }
    }
  });
};

export const setFocusEntityFromDocumentViewer = (
  instance: WebViewerInstance
) => {
  const { annotationManager } = instance.Core;

  annotationManager.addEventListener(
    'annotationSelected',
    (annotations: Core.Annotations.Annotation[]) => {
      // We prevent the selection of the BB in the UI
      const abricoAnnots = annotations.filter((annot) =>
        annot.Id.startsWith(ABRICO_ANNOTATION_PREFIX)
      );
      if (abricoAnnots.length > 0) {
        annotationManager.deselectAnnotations(abricoAnnots);
      }
    }
  );

  annotationManager.addEventListener(
    'annotationDoubleClicked',
    (annotation: Core.Annotations.Annotation) => {
      if (annotation.Id.startsWith(ABRICO_ANNOTATION_PREFIX)) {
        useUiState.getState().setFocusedEntityId(annotation.Id.split('-')[0]);
      }
    }
  );
};

// This is not very robust and may conflict with keyboard auto scroll, so
// I am commenting it out for now
// export const autoScrollFromEntitiesPanelCallback = async (
//   documentViewer: Core.DocumentViewer,
//   rootElem: HTMLElement
// ) => {
//   if (interactionsState.mode === InteractionsMode.FROM_PDF_TO_PANEL) return;
//
//   for (const elem of rootElem.getElementsByClassName('entity-card')) {
//     if (checkElementIsVisible(elem)) {
//       const targetPage = parseInt(
//         elem.attributes.getNamedItem('data-target-page')!.value,
//         10
//       );
//       if (targetPage !== documentViewer.getCurrentPage()) {
//         documentViewer.setCurrentPage(targetPage, true);
//       }
//       return;
//     }
//   }
// };
