// eslint-disable-next-line import/named
import { User as FirebaseUser } from 'firebase/auth';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getUserCompanies } from 'queries/company.ts';
import { getCompanyTags } from 'queries/tags.ts';
import { ICompany, IDossier, ITag } from 'types/index';
import { Dossier } from 'utils/dossier.ts';
import { logDurationInFs } from 'utils/timings.ts';

interface AppState {
  urlParams: Record<string, string>;
  setUrlParams: (params: Record<string, string>) => void;
  getDossierId: () => string;
  user: FirebaseUser | null;
  setUser: (user: FirebaseUser | null) => void;
  userCompanies: ICompany[];
  _setUserCompanies: (companies: ICompany[]) => void;
  company: ICompany | null;
  setCompany: (company: ICompany | null) => void;
  tags: ITag[];
  refreshCompanyTags: () => Promise<void>;
  viewedPdfIsLoadingData: boolean;
  viewedPdfData: Uint8Array | null;
  downloadViewedPdf: (dossier: IDossier) => Promise<Uint8Array>;
}

export const useAppState = create<AppState>()(
  devtools(
    (set, get, store) => ({
      urlParams: {} as Record<string, string>,
      setUrlParams: (params: Record<string, string>) => {
        set({ urlParams: params });
      },
      getDossierId: () => {
        const urlParams = get().urlParams;
        if (urlParams.dossierId) {
          return urlParams.dossierId;
        } else {
          throw new Error('No dossierId in urlParams');
        }
      },
      user: null as FirebaseUser | null,
      setUser: (user: FirebaseUser | null) => {
        set({ user });
      },
      company: null as ICompany | null,
      userCompanies: [] as ICompany[],
      _setUserCompanies: (companies: ICompany[]) => {
        set({ userCompanies: companies });
      },
      setCompany: (company: ICompany | null) => {
        set({
          company,
          tags: get().company?.id === company?.id ? get().tags : [],
        });
      },
      tags: [] as ITag[],
      refreshCompanyTags: async () => {
        const company = get().company;
        if (company) {
          const tags = await getCompanyTags(company.id);
          set({ tags });
        }
      },
      viewedPdfIsLoadingData: false as boolean,
      viewedPdfData: null as Uint8Array | null,
      downloadViewedPdf: async (dossier: IDossier) => {
        // This doesn't support multiple dossier downloads at the same time
        // Will need to be updated if we want to support that
        if (get().viewedPdfData) {
          return get().viewedPdfData as Uint8Array;
        }
        if (get().viewedPdfIsLoadingData) {
          return new Promise((resolve) => {
            const unsubscribe = store.subscribe((state) => {
              if (!state.viewedPdfIsLoadingData) {
                unsubscribe();
                resolve(get().viewedPdfData as Uint8Array);
              }
            });
          });
        }

        set({ viewedPdfIsLoadingData: true });

        const pdfResponse = await fetch(await Dossier.getDownloadUrl(dossier));
        logDurationInFs({
          durationMs: performance.now(),
          name: 'PDF Downloaded',
        });

        set({
          viewedPdfData: new Uint8Array(await pdfResponse.arrayBuffer()),
          viewedPdfIsLoadingData: false,
        });
        return get().viewedPdfData as Uint8Array;
      },
    }),
    {
      name: 'app-store',
    }
  )
);

useAppState.subscribe(async (state, prevState) => {
  if (state.user?.uid !== prevState.user?.uid) {
    if (state.user?.uid) {
      const companies = await getUserCompanies(state.user.uid);
      useAppState.getState()._setUserCompanies(companies);
    }
  }

  if (state.company?.id !== prevState.company?.id) {
    await useAppState.getState().refreshCompanyTags();
  }

  if (
    (state.urlParams?.companyId !== prevState.urlParams?.companyId &&
      state.urlParams?.companyId) ||
    state.userCompanies !== prevState.userCompanies
  ) {
    if (state.userCompanies && state.urlParams?.companyId) {
      const company = state.userCompanies.find(
        (c) => c.id === state.urlParams.companyId
      );
      if (company) {
        useAppState.getState().setCompany(company);
      }
    } else {
      if (state.userCompanies.length && !state.urlParams.companyId) {
        useAppState.getState().setCompany(state.userCompanies[0]);
      }
    }
  }
});
