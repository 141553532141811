import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BlurOn from '@mui/icons-material/BlurOn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import SaveIcon from '@mui/icons-material/Save';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { AccordionGroup, Badge } from '@mui/joy';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/joy/CircularProgress';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useQueryClient } from '@tanstack/react-query';
import { updateProfile } from 'firebase/auth';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { saveReviewFeedback } from 'cloudFunctions/functions.ts';
import { MarkdownEditor } from 'components/Editor/MarkdownEditor.tsx';
import { ReviewStatus } from 'components/Review/ReviewStatus.tsx';
import { getMetaDossierQueryKey } from 'queries/dossiers.ts';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { AnnotStatus, TReviewInfo } from 'types/index';
import { useKeyBoardNavigation } from 'utils/entities.ts';
import { saveDocumentToCloud } from 'utils/file.ts';

function ReviewerInfo() {
  const { t } = useTranslation();

  const user = useAppState((state) => state.user);
  const [showInput, setShowInput] = useState(false);
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    try {
      await updateProfile(user!, { displayName });
      setShowInput(false);
      enqueueSnackbar(t('review.reviewer.saveNotification.success'), {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(t('review.reviewer.saveNotification.error'), {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  }, [displayName, user]);

  return (
    <Stack direction={'column'} gap={1}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={1}
      >
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography>{t('review.statusPrefix')}</Typography>
          <ReviewStatus />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <PersonIcon></PersonIcon>{' '}
          {user?.displayName ? (
            user.displayName
          ) : (
            <>
              <WarningAmberIcon
                // @ts-ignore
                color={'danger'}
              ></WarningAmberIcon>
              {t('review.reviewer.missing')}
            </>
          )}
          <IconButton onClick={() => setShowInput(!showInput)}>
            <EditIcon></EditIcon>
          </IconButton>
        </Stack>
      </Stack>
      {showInput && (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'end'}
          gap={1}
        >
          <Input
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            disabled={isSaving}
          ></Input>
          <IconButton disabled={!displayName || isSaving} onClick={handleSave}>
            <SaveIcon></SaveIcon>
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
}

function ReviewSummary({ reviewInfo }: { reviewInfo: TReviewInfo }) {
  const { t } = useTranslation();
  return (
    <AccordionGroup>
      <Accordion defaultExpanded>
        <AccordionSummary>
          <Typography> {t('review.summary.header')} </Typography>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Badge
              badgeContent={reviewInfo.stats[AnnotStatus.REJECTED]}
              color={'danger'}
            >
              <CancelIcon sx={{ fontSize: '2rem' }} />
            </Badge>
            <Badge
              badgeContent={reviewInfo.stats[AnnotStatus.PENDING]}
              color={'warning'}
            >
              <BlurOn sx={{ fontSize: '2rem' }} />
            </Badge>
            <Badge
              badgeContent={reviewInfo.stats[AnnotStatus.VALIDATED]}
              color={'success'}
            >
              <CheckRoundedIcon sx={{ fontSize: '2rem' }} />
            </Badge>
          </Box>{' '}
        </AccordionSummary>
        <AccordionDetails>
          <List marker={'disc'}>
            {reviewInfo.rejectedEntities.map((el) => (
              <ListItem key={el.id}>
                <Typography>
                  {el.label}
                  {el.kind === 'missing'
                    ? t('review.summary.missingExtra')
                    : ''}
                  {el.rejectionReason !== '' ? ` : ${el.rejectionReason}` : ''}
                </Typography>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  );
}

export function ReviewPanel() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const dossier = useInteractionsState((state) => state.dossier);
  const metaDossierInfo = useInteractionsState(
    (state) => state.metaDossierInfo
  );

  const entitiesRejectionReasons = useInteractionsState(
    (state) => state.entitiesRejectionReasons
  );
  const entitiesStatuses = useInteractionsState(
    (state) => state.entitiesStatuses
  );
  const { preparedEntities } = useKeyBoardNavigation(dossier);

  const reviewInfo = useMemo<TReviewInfo>(() => {
    const emptyStats = {
      [AnnotStatus.VALIDATED]: 0,
      [AnnotStatus.REJECTED]: 0,
      [AnnotStatus.PENDING]: 0,
    };
    if (preparedEntities.length === 0) {
      return {
        stats: emptyStats,
        rejectedEntities: [],
      };
    }

    const allEntities = preparedEntities[0].entitiesForDoc[0].preparedSection
      .flatMap((el) => [
        ...el.missingEntities,
        ...el.groups.flatMap((el) => el.entities),
      ])
      .map((el) => ({
        id: el.id,
        label: el.label,
        status: entitiesStatuses.get(el.id) || AnnotStatus.PENDING,
        rejectionReason: entitiesRejectionReasons.get(el.id) || '',
        kind: el.kind,
      }));

    const stats = allEntities.reduce((acc, el) => {
      acc[el.status]++;
      return acc;
    }, emptyStats);

    return {
      stats,
      rejectedEntities: allEntities.filter(
        (el) => el.status === AnnotStatus.REJECTED
      ),
    };
  }, [entitiesRejectionReasons, entitiesStatuses, preparedEntities]);

  const generateAiReviewFeedbackLoading = useUiState(
    (state) => state.generateAiReviewFeedbackLoading
  );
  const reviewFeedbackMarkdown = useUiState(
    (state) => state.reviewFeedbackMarkdown
  );
  const user = useAppState((state) => state.user);

  const [isSaving, setIsSaving] = useState(false);

  const handleSave = useCallback(
    async ({
      notifyUploader,
      isValid,
    }: {
      notifyUploader: boolean;
      isValid: boolean;
    }) => {
      setIsSaving(true);
      const tmpNotif = enqueueSnackbar(
        t('review.save.notification.inProgress'),
        {
          variant: 'info',
          persist: true,
        }
      );

      try {
        const dossierId = useAppState.getState().getDossierId();
        await saveReviewFeedback({
          dossierId,
          isValid,
          reviewFeedbackMarkdown,
          notifyUploader,
          reviewerName: user!.displayName!,
        });
        // We refetch meta dossier to update the review status
        await queryClient.invalidateQueries({
          queryKey: [getMetaDossierQueryKey(dossierId)],
        });
        enqueueSnackbar(t('review.save.notification.success'), {
          variant: 'success',
        });
        closeSnackbar(tmpNotif);
        // We also trigger a document save to ease ops life
        await saveDocumentToCloud({
          notify: true,
        });
      } catch (err) {
        enqueueSnackbar(t('review.save.notification.error'), {
          variant: 'error',
        });
        closeSnackbar(tmpNotif);
      } finally {
        setIsSaving(false);
      }
    },
    [queryClient, reviewFeedbackMarkdown, t, user]
  );
  if (!metaDossierInfo) {
    return <Typography>{t('sync.noMetaDossier')}</Typography>;
  }

  if (!user?.displayName) {
    return <ReviewerInfo></ReviewerInfo>;
  }

  return (
    <Stack direction={'column'} gap={1}>
      <Stack>
        <ReviewerInfo></ReviewerInfo>
      </Stack>
      <Divider sx={{ mb: 1, mt: 1 }}></Divider>
      <ReviewSummary reviewInfo={reviewInfo} />
      <Stack direction={'row'} gap={1} justifyContent={'center'}>
        <Card color={'warning'}>{t('review.callout')}</Card>
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Button
            endDecorator={
              generateAiReviewFeedbackLoading ? (
                <CircularProgress />
              ) : (
                <AutoAwesomeIcon />
              )
            }
            disabled={
              isInReadOnlyMode || generateAiReviewFeedbackLoading || isSaving
            }
            onClick={() =>
              useUiState.getState().generateAiReviewFeedback(reviewInfo)
            }
          >
            {t('review.ai.btn')}
          </Button>
        </Stack>
      </Stack>
      <Box sx={{ mt: 1, mb: 1 }}>
        <MarkdownEditor
          value={reviewFeedbackMarkdown}
          onChange={(value) => useUiState.getState().setReviewMarkdown(value)}
          readOnly={isInReadOnlyMode || generateAiReviewFeedbackLoading}
          placeholder={t('review.editorPlaceholder')}
        ></MarkdownEditor>
      </Box>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={1}
      >
        <Button
          variant={'soft'}
          disabled={isInReadOnlyMode || isSaving}
          onClick={() => handleSave({ isValid: false, notifyUploader: false })}
        >
          {t('review.save.buttons.invalidateNoClientNotif')}
        </Button>
        <Button
          variant={'soft'}
          disabled={isInReadOnlyMode || !reviewFeedbackMarkdown || isSaving}
          onClick={() => handleSave({ isValid: false, notifyUploader: true })}
        >
          {t('review.save.buttons.invalidate')}
        </Button>
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={1}
        sx={{ mt: 3 }}
      >
        <Button
          variant={'outlined'}
          disabled={isInReadOnlyMode || isSaving}
          onClick={() => handleSave({ isValid: true, notifyUploader: false })}
        >
          {t('review.save.buttons.approve')}
        </Button>
      </Stack>
    </Stack>
  );
}
