import { Core } from '@pdftron/webviewer';
import { serverTimestamp } from 'firebase/firestore';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { PDFDict, PDFDocument, PDFName, PDFRef, PageSizes } from 'pdf-lib';

import i18n from 'i18n.ts';
import { queryClient } from 'queries';
import { getDossierQueryKey, updateDossier } from 'queries/dossiers.ts';
import {
  saveFileDataWithMetadata,
  storeNewFileWithMetadata,
} from 'queries/storage';
import { useAppState } from 'stores/appStore.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { getPDFNet } from 'utils/apryze.ts';
import { getFileData, rescalePagesWidthToA4 } from 'utils/document.ts';

const readFileAsArrayBuffer = async (file: File): Promise<Uint8Array> => {
  const arrayBuffer = await file.arrayBuffer();
  return new Uint8Array(arrayBuffer);
};

// Inspired by https://github.com/Hopding/pdf-lib/issues/905
const getPDFDocFromImageFile = async (
  image: File
): Promise<Core.PDFNet.PDFDoc> => {
  // This code uses pdf-lib internally because it was coded first like this,
  // we might want to switch it entirely to PDFNet in the future
  const pdfDoc = await PDFDocument.create();

  let imagePdf = null;
  if (image.type === 'image/jpeg') {
    imagePdf = await pdfDoc.embedJpg(await readFileAsArrayBuffer(image));
  } else if (image.type === 'image/png') {
    imagePdf = await pdfDoc.embedPng(await readFileAsArrayBuffer(image));
  } else {
    throw new Error(`Image type ${image.type} NOT supported`);
  }

  // We resize the page so that the image fits on it and the width is the one
  // of an A4 page
  let imageDims = imagePdf.size();
  const ratio = imageDims.width / imageDims.height;
  const pageDimensions: [number, number] = [
    PageSizes.A4[0],
    PageSizes.A4[0] / ratio,
  ];

  const pdfPage = pdfDoc.addPage(pageDimensions);

  const { width, height } = pdfPage.getSize();
  // Make sure the image is not larger than the page, and scale down to fit if it is
  if (imageDims.width > width || imageDims.height > height) {
    imageDims = imagePdf.scaleToFit(width, height);
  }

  // Draw image in page, centered horizontally and vertically
  pdfPage.drawImage(imagePdf, {
    x: width / 2 - imageDims.width / 2,
    y: height / 2 - imageDims.height / 2,
    width: imageDims.width,
    height: imageDims.height,
  });

  // We convert it to a PDFNet document
  const PDFNet = await getPDFNet();
  return await PDFNet.PDFDoc.createFromBuffer(await pdfDoc.save());
};

export const getPDFDocFromPdfFile = async (
  file: File,
  forceA4: boolean = true
): Promise<Core.PDFNet.PDFDoc> => {
  const PDFNet = await getPDFNet();
  const doc = await PDFNet.PDFDoc.createFromBuffer(
    await readFileAsArrayBuffer(file)
  );

  if (forceA4) {
    await rescalePagesWidthToA4(
      doc,
      [...Array(await doc.getPageCount()).keys()].map((i) => i + 1)
    );
  }
  return doc;
};

export const mergePDFs = async (
  pdfs: Core.PDFNet.PDFDoc[]
): Promise<Core.PDFNet.PDFDoc> => {
  const PDFNet = await getPDFNet();
  const newDoc = await PDFNet.PDFDoc.create();

  for (const currentPdf of pdfs) {
    const copiedPages: Core.PDFNet.Page[] = [];
    for (
      const itr = await currentPdf.getPageIterator();
      await itr.hasNext();
      await itr.next()
    ) {
      copiedPages.push(await itr.current());
    }

    const importedPages = await newDoc.importPages(copiedPages);
    for (const page of importedPages) {
      await newDoc.pagePushBack(page);
    }
  }

  return newDoc;
};

export const savePDFInBucket = async (
  pdfBytes: Uint8Array,
  filePath: string,
  metadata: Record<string, any>
) => {
  const blob = new Blob([pdfBytes]);

  return await storeNewFileWithMetadata(blob, filePath, metadata);
};

export const mergeFiles = async (files: FileList): Promise<Uint8Array> => {
  const pdfs = [];

  for (const file of files) {
    if (file.type === 'application/pdf') {
      pdfs.push(await getPDFDocFromPdfFile(file));
    } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
      pdfs.push(await getPDFDocFromImageFile(file));
    } else {
      throw new Error(`File type ${file.type} NOT supported`);
    }
  }
  const PDFNet = await getPDFNet();
  const mergedPDF = await mergePDFs(pdfs);
  return await removeApryseWatermark(
    await mergedPDF.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_remove_unused)
  );
};

export const downloadFile = (fileData: ArrayBuffer, fileName: string) => {
  const blob = new Blob([fileData], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(url);
};

/**
 * Helper method to save the raw document to the cloud (with all the proper reodering done)
 * And updated the dossier in Firestore
 */
export const saveDocumentToCloud = async ({
  notify,
}: {
  notify?: boolean;
}): Promise<number | undefined> => {
  const instance = useInteractionsState.getState().instance;
  const dossier = useInteractionsState.getState().dossier;
  const user = useAppState.getState().user;

  if (!instance || !dossier || !user) {
    return;
  }

  let savingNotification;
  if (notify) {
    savingNotification = enqueueSnackbar(
      i18n.t('file.save.notificationSaveInProgress'),
      {
        variant: 'info',
        autoHideDuration: null,
      }
    );
  }
  try {
    useInteractionsState.getState().enableReadOnlyMode(true);
    console.time('Saving PDF file: ');
    const fileData = await getFileData(instance);

    const newPdfFileVersion = dossier.pdfFileVersion
      ? dossier.pdfFileVersion + 1
      : 1;

    await saveFileDataWithMetadata(
      dossier.gcsInputFolderPath,
      dossier.getGcsFileName(newPdfFileVersion),
      await removeApryseWatermark(fileData),
      user.email!
    );

    await updateDossier(
      dossier.id,
      {
        updatedAt: serverTimestamp(),
        disableAutoReorder: true,
        pdfFileVersion: newPdfFileVersion,
        displayedPageNumberByWorkflowPageNumber:
          dossier.updatedDisplayedPageNumberByWorkflowPageNumber,
        metadataIsFromWorkflow: false,
      },
      useInteractionsState.getState().getDataForFs()
    ).then(() =>
      queryClient.invalidateQueries({
        queryKey: [getDossierQueryKey(dossier.id)],
      })
    );
    useInteractionsState.getState().setHasChangesThatRequireSave(false);

    if (notify) {
      enqueueSnackbar(i18n.t('file.save.notificationSaveSucceeded'), {
        variant: 'success',
        autoHideDuration: 3000,
      });

      closeSnackbar(savingNotification);
    }

    return newPdfFileVersion;
  } catch (err) {
    console.error('Error in saving updated file:', err);

    enqueueSnackbar(i18n.t('file.save.notificationSaveFailed'), {
      variant: 'error',
    });
  } finally {
    console.timeEnd('Saving PDF file: ');
    useInteractionsState.getState().disableReadOnlyMode();
  }
};

/**
 * Helper method to remove the Apryse watermark from the document
 */
export const removeApryseWatermark = async (
  fileData: Uint8Array,
  shouldHaveWatermark: boolean = true
): Promise<Uint8Array> => {
  // We duplicate the bytes to avoid issues
  const pdfDoc = await PDFDocument.load(Uint8Array.from(fileData));
  let hadWatermark = pdfDoc.getPageIndices().length === 0;

  pdfDoc.getPages().forEach((page) => {
    const xObject = page.node.Resources()?.get(PDFName.XObject);
    if (xObject instanceof PDFDict) {
      // the watermark is always identified by the same nasty key
      const watermarkRef = xObject.get(PDFName.of('Trn3dK9'));
      // if we find it, we delete it :tada:
      if (watermarkRef instanceof PDFRef) {
        page.node.Resources()!.context.delete(watermarkRef);
        hadWatermark = true;
      }
    }
  });
  if (shouldHaveWatermark && !hadWatermark) {
    console.error(
      'No Apryse watermark found in the document, this is unexpected'
    );
  }
  return await pdfDoc.save({ useObjectStreams: false });
};
