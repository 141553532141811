import { ToggleButtonGroup } from '@mui/joy';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import DocEntities from 'components/Entity/DocEntities.tsx';
import useWindowDimensions from 'hooks/useWindowDimensions.ts';
import { useInteractionsState } from 'stores/interactionsStore.ts';
import { usePersistedStore } from 'stores/persistedStore.ts';
import { useUiState } from 'stores/uiStore.ts';
import { AnnotStatus } from 'types/index';
import { getEntitiesStatusFromAnnotations } from 'utils/annotations.ts';
import { Dossier } from 'utils/dossier.ts';
import { useKeyBoardNavigation } from 'utils/entities.ts';
import { interactionsState } from 'utils/interactions.ts';
import { logDurationInFs } from 'utils/timings.ts';

const EntitiesPanel = ({
  dossier,
  panelRef,
  entityGlobalAutoSelectRef,
}: {
  entityGlobalAutoSelectRef: MutableRefObject<() => void>;
  dossier?: Dossier;
  panelRef: RefObject<HTMLDivElement>;
}) => {
  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.focus();
    }
  }, [panelRef]);

  const { t } = useTranslation('common');
  const [initHasRun, setInitHasRun] = useState(false);
  const instance = useInteractionsState((state) => state.instance);
  const hideReviewedEntitiesSettings = usePersistedStore(
    (state) => state.hideReviewedEntitiesSettings
  );
  const setHideReviewedEntitiesSettings = usePersistedStore(
    (state) => state.setHideReviewedEntitiesSettings
  );

  const { preparedEntities, goNext, goPrevious, setDocIsExpanded } =
    useKeyBoardNavigation(dossier);

  useEffect(() => {
    if (instance && !initHasRun) {
      setInitHasRun(true);
      const { Core } = instance;
      const { documentViewer, annotationManager } = Core;

      documentViewer.addEventListener('annotationsLoaded', async () => {
        annotationManager.getAnnotationsList().forEach((annotation) => {
          if (annotation instanceof Core.Annotations.FreeTextAnnotation) {
            annotation.FillColor = new instance.Core.Annotations.Color(
              0,
              0,
              0,
              0
            );
            alert(
              `Une annotation mentionnant le texte '${annotation.getContents()}' susceptible de cacher un autre texte était présente sur le PDF page ${annotation.getPageNumber()}. Nous avons retiré la couleur de fond de cette dernière.`
            );
          }
        });

        const documentFullyLoaded = useUiState.getState().documentFullyLoaded;
        if (!documentFullyLoaded) {
          useInteractionsState
            .getState()
            .getDossier()
            .then(async (notNullDossier) => {
              // FIXME remove this
              // once data has been migrated: https://www.notion.so/abrico/Rework-entities-ID-logic-strip-mentionText-of-ids-and-have-a-non-annonymized-version-will-need-mig-24095f06901740dca808e17edbe6ee86?pm=c
              useInteractionsState.getState().mergeData({
                entitiesStatuses: getEntitiesStatusFromAnnotations(instance),
              });
              await notNullDossier.refreshUI();

              useUiState.getState().setDocumentFullyLoaded(true);
              logDurationInFs({
                durationMs: performance.now(),
                name: 'Viewer Ready',
              });
            });
        }
      });
    }
  }, [instance, dossier, initHasRun]);

  const { height } = useWindowDimensions();

  const focusedEntityId = useUiState(
    useShallow((state) => state.focusedEntityId)
  );

  useEffect(() => {
    if (instance && focusedEntityId) {
      const { annotationManager } = instance.Core;
      const annot = annotationManager.getAnnotationById(focusedEntityId);
      // On chrome we cannot scroll 2 panels at the same time
      // Since we are focusing entities from the DPF too, we shouldn't always jump to it
      if (annot && interactionsState.mode === 'FROM_PANEL_TO_PDF')
        annotationManager.jumpToAnnotation(annot, {
          isSmoothScroll: true,
        });
    }
  }, [focusedEntityId, instance]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent, currentId: string) => {
      if (
        !(
          e.key === 'ArrowDown' ||
          e.key === 'ArrowUp' ||
          e.key === 'Enter' ||
          e.key === 'ArrowLeft' ||
          e.key === 'ArrowRight'
        )
      ) {
        return;
      }
      e.preventDefault();
      if (e.key === 'ArrowDown') goNext();
      else if (e.key === 'ArrowUp') goPrevious();
      else if (e.key === 'Enter' || e.key === 'ArrowRight') {
        useInteractionsState
          .getState()
          .setEntityStatus(currentId, AnnotStatus.VALIDATED);
      } else if (e.key === 'ArrowLeft') {
        useInteractionsState
          .getState()
          .setEntityStatus(currentId, AnnotStatus.REJECTED);
      }
    },
    [goNext, goPrevious]
  );

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(e, focusedEntityId)}
      ref={panelRef}
      style={{ outline: 'none' }}
    >
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={1}
      >
        <Typography level={'body-xs'}>Entités affichées</Typography>
        <ToggleButtonGroup
          size={'sm'}
          value={hideReviewedEntitiesSettings.toString()}
          onChange={(_e, newValue) => {
            setHideReviewedEntitiesSettings(newValue === 'true');
          }}
        >
          <Button value="true">En attente</Button>
          <Button value="false">Toutes</Button>
        </ToggleButtonGroup>
      </Stack>
      {preparedEntities.map(({ entitiesForDoc, docType }) => (
        <Stack
          key={docType}
          direction="column"
          sx={{ width: '100%', marginTop: 2 }}
        >
          <Typography
            level="title-lg"
            style={{ textAlign: 'center' }}
            sx={{ fontWeight: 'bold' }}
            textTransform={'uppercase'}
          >
            {t(`docTypes.${docType}.name`)}
          </Typography>
          {entitiesForDoc.map(({ preparedSection, docKey, isLastDoc }) => (
            <div
              key={docKey}
              style={
                // Hacky way to add some space at the bottom to make sure
                // the last section can be displayed entirely from the top (looks nicier)
                isLastDoc ? { minHeight: '100vh' } : {}
              }
            >
              <DocEntities
                {...{
                  docType,
                  docKey,
                  preparedSection,
                  setDocIsExpanded,
                  scrollOffset: height * 0.2,
                  entityGlobalAutoSelectRef,
                }}
                docExtraLabel={dossier!.docExtraLabelByDocKey[docKey]}
              />
            </div>
          ))}
        </Stack>
      ))}
    </div>
  );
};

export default EntitiesPanel;
