import {
  Autocomplete,
  AutocompleteOption,
  Box,
  Button,
  DialogTitle,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import Bowser from 'bowser';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { db } from 'config/firebaseInit';
import { forceSaveSentryReplay } from 'instrument.ts';
import { useAppState } from 'stores/appStore.ts';

interface FeedbackData {
  companyId: string | undefined;
  userEmail: string | undefined | null;
  dossierId: string | undefined;
  feedback: string;
  docTypes: string[];
  createdAt: any;
  browser: any;
}

interface UserFeedbackModalProps {
  open: boolean;
  onClose: () => void;
  docTypes: string[];
}

const UserFeedbackModal: React.FC<UserFeedbackModalProps> = ({
  open,
  onClose,
  docTypes,
}) => {
  const [feedback, setFeedback] = useState('');
  const [isFeedbackValid, setIsFeedbackValid] = useState(false);
  const [selectedDocTypes, setSelectedDocTypes] = useState<string[]>([]);
  const { user, company } = useAppState(
    useShallow((state) => ({ user: state.user, company: state.company }))
  );
  const { t } = useTranslation('common');

  const handleFeedbackChange = (event: any) => {
    const newFeedback = event.target.value;
    setFeedback(newFeedback);
    setIsFeedbackValid(newFeedback.trim() !== ''); // Validate that feedback is not just whitespace
  };

  const handleSubmitFeedback = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const feedbackData: FeedbackData = {
      companyId: company?.id,
      userEmail: user?.email,
      dossierId: useAppState.getState().getDossierId(),
      feedback,
      docTypes: selectedDocTypes,
      browser: Bowser.getParser(window.navigator.userAgent).getResult(),
      createdAt: serverTimestamp(),
    };
    await addDoc(collection(db, 'feedbacks'), feedbackData);
    // We force save a sentry replay to have better understanding of the issue
    await forceSaveSentryReplay();
    enqueueSnackbar(t('userFeedback.success'), {
      variant: 'success',
    });
    setFeedback('');
    setSelectedDocTypes([]);
    onClose();
  };

  const handleClose = () => {
    setFeedback('');
    setSelectedDocTypes([]);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="feedback-modal-title"
      aria-describedby="feedback-modal-description"
    >
      <ModalDialog size="md" sx={{ minWidth: { md: 400 } }}>
        <DialogTitle>{t('userFeedback.title')}</DialogTitle>
        <form onSubmit={handleSubmitFeedback}>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel> {t('userFeedback.select')}</FormLabel>
              <Autocomplete
                multiple
                id="feedback-type"
                options={docTypes}
                value={selectedDocTypes}
                // @ts-expect-error
                onChange={(event: any, newValue: any) =>
                  setSelectedDocTypes(newValue)
                }
                renderOption={(props, option) => (
                  <AutocompleteOption {...props}>
                    <Typography level="body-md">
                      {t(`docTypes.${option}.name`)}
                    </Typography>
                  </AutocompleteOption>
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel> {t('userFeedback.write')}</FormLabel>
              <Textarea
                value={feedback}
                autoFocus
                required
                minRows={4}
                onChange={handleFeedbackChange}
              />
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row-reverse' },
                mt: 3,
              }}
            >
              <Button type="submit" variant="solid" disabled={!isFeedbackValid}>
                {t('userFeedback.submit')}
              </Button>
              <Button onClick={handleClose} variant="outlined">
                {t('userFeedback.cancel')}
              </Button>
            </Box>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default UserFeedbackModal;
